import { useCallback, useMemo } from 'react'

import { useFilteredShotsWithContext, useShotsWithContext } from './use-shots-with-context'

/**
 * @typedef {object} ShotExplorerFilter
 * @property {Array<string>} [sequences] If provided, only shots which occurred
 *   at the specified point in the rally are allowed. Valid values include
 *   serve, return, final as well as 3rd, 4th, 5th, 6th, 7th, 8th, and 9th.
 * @property {Array<string>} [highlights] If provided, only shots which occurred
 *   during ANY of the specified highlights kinds are included. For a list of
 *   allowed kinds (e.g., atp) see https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=highlights
 * @property {Array<int>} players A list of player IDs (e.g., 0, 1, 2 or 3)
 *   whose shots should be included.
 * @property {QualityRange} quality if min isn't 0 or max isn't 1, then only
 *   shots within the specified quality range will be included
 * @property {Array<string>} [types] If provided, only shot types included in
 *   the list will be included. For valid values, see the shot_type field here: https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies.moments.ball.shot
 * @property {string} [strokeSide] If omitted, nothing will be filtered out. Can
 *   also be "forehand" or "backhand".
 * @property {string} [strokeType] If omitted, nothing will be filtered out. Can
 *   also be "forehand" or "backhand".
 * @property {string} [verticalType] If omitted, nothing will be filtered out.
 *   Can be "dig", "overhead" or "neutral".
 * @property {string} [winnerType] If omitted, nothing will be filtered out.
 *   Can be "clean" or "forced_fault".
 * @property {Array<string>} [directions] If provided, only shots whose angle
 *   is one of the provided values will be included. See the direction field here for valid values: https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies.moments.ball.shot.resulting_ball_movement.angles
 * @property {Array<string>} [characteristics] If provided, only shots with
 *   ALL of the provided characteristics will be included. Valid values include:
 *     * Errors: error, error-dead-dink, error-popup
 *     * Faults: fault, fault-double-bounce, fault-kitchen, fault-net, fault-out, fault-paddle-hit-net
 *     * Others: passing, poach, reset, speedup, volley
 * @property {ShowWindow} shotWindow How many additional shots to include
 *   around each selected shot.
 */
/**
 * @typedef {object} QualityRange
 * @property {double} min the minimum quality to include (0.0 is the min value)
 * @property {double} max the maximum quality to include (1.0 is the max value)
 */
/**
 * @typedef {object} ShowWindow
 * @property {int} numBefore how many shots before a selected shot should be included (0 for no extra shots before each selected shot)
 * @property {int} numAfter how many shots after a selected shot should be included (0 for no extra shots after each selected shot)
 */

/**
 * @param {object} [insights] the insights object (may be omitted)
 * @param {ShotExplorerFilter} shotExplorerFilter
 * @returns {Array<ShotWithContext>}
 */
export function useShotsWithContextFromShotExplorerFilter (insights, shotExplorerFilter) {
  const shotsWithContext = useShotsWithContext(insights)

  const isDuringChosenHighlight = useCallback(ms => {
    for (const highlight of (insights?.highlights ?? [])) {
      if (ms >= highlight.s && ms <= highlight.e) {
        if (shotExplorerFilter.highlights.indexOf(highlight.kind) !== -1) {
          return true
        }
      }
    }
  }, [insights?.highlights, shotExplorerFilter.highlights])

  const predicate = useCallback(shotWithContext => {
    // only included shots from the specified player(s)
    const { shot } = shotWithContext
    const { player_id: playerId } = shot
    if (shotExplorerFilter.players.indexOf(playerId) === -1) {
      return false
    }

    // don't filter on quality if slider is set to include everything
    const { min, max } = shotExplorerFilter.quality
    if (min !== 0 || max !== 1) {
      // if we're filtering on quality and quality isn't set for this shot,
      // then this shot will be excluded
      const quality = shot.quality.overall ?? -1
      if (quality < min || quality > max) {
        return false
      }
    }

    // only filter by shot type if the list is non-empty
    if (shotExplorerFilter.types.length) {
      if (shotExplorerFilter.types.indexOf(shot.shot_type) === -1) {
        return false
      }
    }

    // various equality filters on the shot object
    const filterFieldToShotField = {
      strokeSide: 'stroke_side',
      strokeType: 'stroke_type',
      verticalType: 'vertical_type',
      winnerType: 'winner_type'
    }
    for (const [filterKey, shotKey] of Object.entries(filterFieldToShotField)) {
      const filterValue = shotExplorerFilter[filterKey]
      if (filterValue) {
        if (shot[shotKey] !== filterValue) {
          return false
        }
      }
    }

    // filter by direction of ball movement, if requested
    const { directions } = shotExplorerFilter
    if (directions?.length) {
      // direction is undefined if we don't know it
      const direction = shot.resulting_ball_movement?.angles?.direction
      if (directions.indexOf(direction) === -1) {
        return false
      }
    }

    // filter by when in the rally the shot occurred, if requested
    if (shotExplorerFilter.sequences.length) {
      if (!shotExplorerFilter.sequences.includes(shotWithContext.shotSequence) && !(shotExplorerFilter.sequences.includes('final') && shot.is_final)) {
        return false
      }
    }

    // filter by other boolean characteristics (must have ALL)
    const { characteristics } = shotExplorerFilter
    for (const characteristic of characteristics) {
      if (!hasCharacteristic(shot, characteristic)) {
        return false
      }
    }

    // only include shots that occur during some highlight
    if (shotExplorerFilter?.highlights?.length) {
      if (!isDuringChosenHighlight(shotWithContext.mStart)) {
        return false
      }
    }

    // filter by shot type: volley or groundstroke
    if (shotExplorerFilter?.groundStrokeOrVolley) {
      // shot.is_volley can be undefined
      const isVolley = shot?.is_volley

      if (isVolley === undefined) {
        return false
      }

      if (!isVolley && shotExplorerFilter.groundStrokeOrVolley === 'volley') {
        return false
      }

      if (isVolley && shotExplorerFilter.groundStrokeOrVolley === 'groundstroke') {
        return false
      }
    }

    return true
  }, [isDuringChosenHighlight, shotExplorerFilter])

  const filteredShots = useFilteredShotsWithContext(insights, predicate)

  // if we we asked for context, get the shots around each selected shot
  const { numBefore, numAfter } = shotExplorerFilter.shotWindow

  return useMemo(() => {
    if (!numBefore && !numAfter) {
      return filteredShots
    }

    // compute which shots we want from each rally
    const filteredShotIndexesByRally = {} // rally idx -> set of shot indexes to pick
    const pickedShotIndexesByRally = {} // rally idx -> set of shot indexes to pick
    for (const { rallyIdx, shotIdx } of filteredShots) {
      if (!pickedShotIndexesByRally[rallyIdx]) {
        pickedShotIndexesByRally[rallyIdx] = new Set()
      }
      if (!filteredShotIndexesByRally[rallyIdx]) {
        filteredShotIndexesByRally[rallyIdx] = []
      }
      filteredShotIndexesByRally[rallyIdx].push(shotIdx)
      // numBefore is already negative, sum it with shotIdx otherwise (--) becomes +
      for (let i = Math.max(0, shotIdx + numBefore); i <= shotIdx + numAfter; i++) {
        // i may be after the last shot in the rally; that's okay
        pickedShotIndexesByRally[rallyIdx].add(i)
      }
    }

    const picked = []
    for (const shotWithContext of shotsWithContext) {
      const pickedShotIndexesInRally = pickedShotIndexesByRally[shotWithContext.rallyIdx]
      if (pickedShotIndexesInRally?.has(shotWithContext.shotIdx)) {
        // If this is originally filtered shot just copy the reference over
        if (filteredShotIndexesByRally[shotWithContext.rallyIdx].includes(shotWithContext.shotIdx)) {
          picked.push(shotWithContext)
        } else {
          // Otherwise set `isContext` attribute to true so we know it's only for video play
          picked.push(Object.assign({}, shotWithContext, { isContext: true }))
        }
      }
    }
    return picked
  }, [filteredShots, numBefore, numAfter, shotsWithContext])
}

function hasCharacteristic (shot, characteristic) {
  // errors
  if (characteristic === 'error') {
    return Boolean(shot.errors)
  }
  if (characteristic === 'error-dead-dink') {
    return shot.errors?.is_dead_dink === true
  }
  if (characteristic === 'error-popup') {
    return shot.errors?.is_popup === true
  }

  // faults
  if (characteristic === 'fault') {
    return Boolean(shot.errors?.faults)
  }
  if (characteristic === 'fault-double-bounce') {
    return shot.errors?.faults?.excess_bounce === true
  }
  if (characteristic === 'fault-kitchen') {
    return shot.errors?.faults?.kitchen === true
  }
  if (characteristic === 'fault-net') {
    return shot.errors?.faults?.net === true
  }
  if (characteristic === 'fault-out') {
    return shot.errors?.faults?.out === true
  }
  if (characteristic === 'fault-paddle-hit-net') {
    return shot.errors?.faults?.paddle_hit_net === true
  }

  // other
  if (characteristic === 'passing') {
    return shot.is_passing
  }
  if (characteristic === 'poach') {
    return shot.is_poach
  }
  if (characteristic === 'reset') {
    return shot.is_reset
  }
  if (characteristic === 'speedup') {
    return shot.is_speedup
  }
  if (characteristic === 'volley') {
    return shot.is_volley
  }
  throw new Error('unknown characteristic ' + characteristic)
}
