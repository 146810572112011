// This slice stores data for shot filters, persisting them with local storage
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { createLocalStorageState } from './local-storage-state'

export const sliceName = 'shotFilters'
const initialFilters = {
  customTags: [],
  characteristics: [],
  directions: [],
  highlights: [],
  players: [0, 1, 2, 3],
  strokeSide: null,
  sequences: [],
  quality: { min: 0, max: 1 },
  types: [],
  groundStrokeOrVolley: null,
  strokeType: null,
  shotWindow: { numBefore: -1, numAfter: 1 }
}

const { preloadedState, saveState } = createLocalStorageState({
  sliceName,
  defaultState: {
    savable: initialFilters
  }
})

const shotFiltersSlice = createSlice({
  name: sliceName,
  initialState: preloadedState,
  reducers: {
    setAllFilters: (state, action) => {
      Object.assign(state, action.payload)
      saveState(state)
    },
    setShotFilters: (state, action) => {
      const { type, value } = action.payload

      state[type] = value
      saveState(state)
    },
    clearFilters: (state, action) => {
      Object.assign(state, initialFilters)
      saveState(state)
    }
  }
})

export const selectShotFilters = state => state[sliceName]
export const useShotFilters = () => useSelector(selectShotFilters)
export const { reducer } = shotFiltersSlice
export const { setAllFilters, setShotFilters, clearFilters } = shotFiltersSlice.actions
