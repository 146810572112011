import { useMemo } from 'react'

/**
 * @typedef {object} ShotWithContext
 * @property {int} rallyIdx the rally in which the shot was hit (0-indexed)
 * @property {Rally} rally see insights documentation (https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies)
 * @property {Moment} moment see insights documentation (https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies.moments)
 * @property {Shot} shot see insights documentation (https://pbv-public.github.io/insights/?s=%2Finsights%2Fgame&p=rallies.moments.ball.shot)
 * @property {int} shotIdx how many shots were hit before this one in the rally (0 means this is the first shot in the rally)
 */

/**
 * @param {Insights} [insights]
 * @returns {Array<ShotWithContext>}
 */
export function useShotsWithContext (insights) {
  return useMemo(() => {
    const shotsWithContext = []
    let rallyIdx = 0
    const rallies = insights?.rallies ?? []

    for (;rallyIdx < rallies.length; rallyIdx++) {
      const rally = rallies[rallyIdx]
      let shotIdx = 0

      const shots = rally.shots

      for (;shotIdx < shots.length; shotIdx++) {
        const shot = shots[shotIdx]
        const resultingBallMovement = shot.resulting_ball_movement

        if (resultingBallMovement) {
          // Needs to be here in order to properly combine with other filters,
          // once the collection is filtered - sequence is lost
          let shotSequence
          if (shotIdx === 0) shotSequence = 'serve'
          else if (shotIdx === 1) shotSequence = 'return'
          else if (shotIdx === 2) shotSequence = '3'
          else if (shotIdx === 3) shotSequence = '4'

          shotsWithContext.push({
            shot,
            rally,
            rallyIdx,
            shotIdx,
            shotSequence,

            // Put frequently used props in here to speedup the reading
            rStart: rally.start_ms,
            rEnd: rally.end_ms,
            mStart: resultingBallMovement.trajectory.start.ms,
            mEnd: resultingBallMovement.trajectory.end.ms
          })
        }
      }
    }

    return shotsWithContext
  }, [insights?.rallies])
}

export function useFilteredShotsWithContext (insights, predicate) {
  const shotsWithContext = useShotsWithContext(insights)
  return useMemo(() => {
    return shotsWithContext.filter(predicate)
  }, [predicate, shotsWithContext])
}
