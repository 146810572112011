import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

import { playerColors } from '../helpers'

import COLORS from '@/utils/colors'

export const Drawer = styled(MuiDrawer)({
  '& .MuiPaper-root': {
    backgroundColor: COLORS['neutral-100'],
    width: 393
  },
  '& .head': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '150%',
    color: COLORS['neutral-700'],
    background: '#fff',
    padding: 16,
    '& button': {
      marginLeft: 'auto',
      color: COLORS['neutral-400']
    }
  },
  '& .filters-content': {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: 16
  },
  '& .title': {
    display: 'flex',
    color: COLORS['neutral-700'],
    margin: '16px 16px 12px 16px',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '166%',
    button: {
      color: COLORS['text-tertiary'],
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'none',
      marginLeft: 'auto'
    },
    '& > .tooltip': {
      marginLeft: 'auto'
    }
  },
  '& .experimental-icon': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    '& svg': {
      cursor: 'pointer'
    }
  },
  '& .strike-types': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
    gap: '8px'
  },

  '& .shot-quality': {
    margin: '0 16px',
    // Slider marks
    '& > .row > span': {
      borderRadius: 24,
      fontSize: 12,
      padding: 8,
      fontWeight: 500,
      background: '#fff'
    },
    '& .excellent': {
      marginLeft: 'auto',
      color: COLORS['Quality_scale-Excellent']
    },
    '& .poor': {
      color: COLORS['Quality_scale-Poor']
    }
  },
  '& .shot-window': {
    margin: '0 18px',
    '& .MuiSlider-root': {
      color: COLORS['neutral-400']
    },
    '& .MuiSlider-track': {
      // opacity: 0.72
    },
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      border: `2px solid ${COLORS['neutral-200']}`,
      background: COLORS['neutral-500'],
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      },
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'none'
      }
    },
    '& .MuiSlider-markLabel': {
      padding: '0 8px',
      minHeight: 24,
      borderRadius: 24,
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      '&.MuiSlider-markLabelActive': {
        background: COLORS['neutral-500'],
        color: '#fff'
      }
    }
  },
  '& .stroke-sides': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '12px 16px',
    '& button': {
      flexGrow: 1,
      backgroundColor: COLORS['neutral-200'],
      '&.selected': {
        color: '#fff',
        backgroundColor: COLORS['neutral-500']
      }
    }
  },
  '& section': {
    margin: '0 16px',
    '&.players': {
      borderRadius: 8,
      margin: '0 16px',
      border: `1px solid ${COLORS['neutral-300']}`,
      fontSize: 14,
      fontWeight: 600,
      overflow: 'hidden',
      '& .selected, & .selected button': {
        background: `${COLORS['neutral-500']} !important`,
        color: '#fff !important',
        '& svg': {
          color: '#fff !important'
        }
      },
      '& .button': {
        alignItems: 'center',
        background: '#fff',
        display: 'flex',
        color: 'inherit',
        flexDirection: 'row',
        flexGrow: 1,
        height: 50,
        padding: 12,
        // width: '100%',
        borderRadius: 0,

        '& .player': {
          marginRight: 8
        },
        '& svg': {
          color: COLORS['neutral-400'],
          marginLeft: 'auto'
        }
      }
    }
  },
  // Avatar in player section
  '& .p-wrap': {
    display: 'flex',
    flexDirection: 'row',
    background: '#fff',
    '& .edit-icon': {
      color: COLORS['neutral-400']
    },
    '& > .img': {
      border: 'none',
      marginRight: 4,
      // '& > div': {
      //   display: 'none'
      // },
      '& .pi__img': {
        width: '50% !important'
      }
    },
    // Inline edit
    '& span button': {
      flexGrow: 1
    }
  },
  '& .row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '& .space-between': {
    justifyContent: 'space-between'
  },
  ...playerColors
})
