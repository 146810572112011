import { Popper, Box, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

/**
 * TagPlayerPopup container
 *
 * @returns {React.ReactElement}
 */
export const Container = styled(Popper)({
  zIndex: 1300,
  width: '510px',
  maxWidth: 'calc(100% - 5px)',
  padding: '20px',
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fbfcfe',
  borderColor: '#cdd7e1',
  borderRadius: '12px',
  borderStyle: 'solid',
  borderWidth: '1px',
  boxShadow: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    '& .title': {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '166%',
      textAlign: 'left',
      color: COLORS['neutral-700']
    },
    '& .close-icon': {
      cursor: 'pointer',
      fontSize: '22px',
      color: '#636b74'
    }
  },
  '& .content': {
    display: 'flex',
    alignItems: 'center',
    '& .player-images': {
      display: 'flex',
      border: `3px solid ${COLORS.player1}`,
      borderRadius: '4px',
      '& img': {
        width: '30px',
        height: '68px'
      }
    },
    '& .avatar': {
      position: 'relative',
      left: '-8px',
      width: '35px',
      minWidth: '35px',
      height: '35px',
      borderRadius: '50%',
      backgroundColor: COLORS.player1,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-root': {
      flex: '1',
      '& .MuiFormControl-root': {
        width: '100%',
        '& .text-field': {
          '& .icon': {
            color: '#636B74'
          },
          '& .MuiInputBase-root': {
            height: '40px',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '150%',
            paddingLeft: '7px'
          }
        }
      },
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        top: '41px'
      }
    }
  }
})

/**
 * Autocomplete Paper container
 *
 * @returns {React.ReactElement}
 */
export const AutocompletePaper = styled(Paper)({
  width: '241px',
  maxWidth: '100%',
  '& .MuiAutocomplete-listbox': {
    padding: '0',
    maxHeight: '222px'
  },
  '& .footer': {
    padding: '6px 16px',
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #DDE7EE',
    '& .add-new': {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '20px',
      color: '#32383E',
      textTransform: 'none',
      borderColor: '#CDD7E1'
    }
  }
})

/**
 * Autocomplete Option container
 *
 * @returns {React.ReactElement}
 */
export const AutocompleteOption = styled(Box)({
  height: '64px',
  borderBottom: '1px solid #DDE7EE',
  '& .option-content': {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    color: COLORS['neutral-700'],
    '& .avatar': {
      width: '48px',
      height: '48px',
      borderRadius: '24px',
      border: `1px solid ${COLORS['neutral-400']}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .initials': {
        fontFamily: 'Inter',
        fontSize: '17px',
        lineHeight: '100%',
        fontWeight: '400'
      }
    },
    '& .name-section': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '& .name': {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        textAlign: 'left',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical'
      },
      '& .info': {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: '16px',
        textAlign: 'left'
      }
    }
  }
})
