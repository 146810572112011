import { styled } from '@mui/material/styles'

import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { round as roundNum } from '@/utils/helper'

/**
 * BarChart component - vertical or horizontal with multiple values and configurable colors
 *
 * @exports
 * @param {*} props
 * @returns {React.ReactElement}
 */
export function BarChart (props) {
  const {
    values = [],
    width,
    sx,
    isVertical,
    emptySpaceColor = false,
    single = false,
    reversed = false,
    withoutLabels = false,
    isValuesRounded = true
  } = props

  // Process values
  const processedValues = values.map((single, index) => {
    const val = isValuesRounded ? roundNum(single.value) : single.value
    return {
      ...single,
      value: withoutLabels ? val : val < 8 ? 8 : val
    }
  }
  )

  function generateValue (params) {
    if (params.customValue) {
      return params.customValue
    } else {
      return params.value > 10 ? `${params.value} %` : ''
    }
  }

  // Compute the remaining space if values don't sum to 100%
  const remainingValue = 100 - processedValues.reduce((acc, val) => acc + val.value, 0)
  if (!single && remainingValue > 0) {
    processedValues.push({ value: remainingValue, fillColor: emptySpaceColor || 'transparent' })
  }

  if (single) {
    const chart = processedValues[0]
    const value = generateValue(chart)

    return (
      <BarChartContainer vertical={isVertical} width={width} sx={sx} className={cls('bar-chart', reversed && 'reversed')}>
        <div
          style={{
            [isVertical ? 'height' : 'width']: `${chart.value}%`,
            backgroundColor: chart?.fillColor || 'transparent'
          }}
        >
          {processedValues[0].value >= 50 && <span style={{ color: chart?.textColor || COLORS.white }}>{value}</span>}
        </div>
        <div
          style={{
            [isVertical ? 'height' : 'width']: `${100 - chart.value}%`,
            backgroundColor: 'transparent'
          }}
        >
          {processedValues[0].value < 50 && <span style={{ color: COLORS.black }}>{value}</span>}
        </div>
      </BarChartContainer>
    )
  }

  return (
    <BarChartContainer vertical={isVertical} width={width} sx={sx} className='bar-chart'>
      {processedValues.map((single, index) => (
        <div
          key={index}
          style={{
            [isVertical ? 'height' : 'width']: `${single.value}%`,
            backgroundColor: single?.fillColor || 'transparent'
          }}
        >
          {!withoutLabels &&
            <span style={{ color: single?.textColor || COLORS.white, padding: 8 }}>{generateValue(single)}</span>}
        </div>
      ))}
    </BarChartContainer>
  )
}

/**
 * Bar chart container
 *
 * @param props {object} { width, vertical } - Width of the chart, Vertical for vertically aligned charts
 * @returns {React.ReactElement}
 */
const BarChartContainer = styled('div', {
  skipProps: ['vertical', 'width']
})(({ width, vertical }) => ({
  display: 'flex',
  flexGrow: 1,

  '&.reversed': {
    flexDirection: vertical ? 'column-reverse' : 'row-reverse'
  },

  '& div': {
    display: 'flex',
    color: COLORS.white,
    alignItems: 'center',
    justifyContent: 'center'
  },
  ...(width ? { width: `${width}px` } : {}),
  ...(vertical
    ? {
        justifyContent: 'flex-end',
        flexDirection: 'column',
        '& > div': {
          flexDirection: 'column',
          placeContent: 'center'
        }
      }
    : {
        flexDirection: 'row',
        '& > div': {
          overflow: 'hidden',
          '& span': {
            fontSize: '12px',
            fontWeight: 700,
            whiteSpace: 'nowrap',
            cursor: 'default'
          }
        }
      })
}))
