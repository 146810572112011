import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Dialog, IconButton, styled } from '@mui/material'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Avatar } from '@/components/avatar'
import { BarChart } from '@/components/bar-chart'
import { Counter } from '@/components/counter'
import { ExperimentalIcon } from '@/components/experimental-icon'
import { PlayerImage } from '@/components/player-image'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useRenamePlayer } from '@/pages/home/hooks/use-rename-player'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { scrollToTopOfMain } from '@/utils/helpers/scroll-to-top'
import { shotQualityLevels } from '@/utils/shot-quality-levels'

function ShotsDialog ({ playerShots, onClose, userData, playerIdx }) {
  const navigate = useNavigate()
  const { vid } = useParams()
  const isMobile = useMobileDetect()
  const playerName = userData.players[playerIdx]?.name || `Player ${playerIdx + 1}`

  function createExploreUrl (minQuality, maxQuality, playerId) {
    const params = new URLSearchParams()

    // Add quality[min] and quality[max] parameters
    params.append('quality[min]', minQuality)
    params.append('quality[max]', maxQuality)

    // Add players[0] parameter
    params.append('players[0]', playerId)

    // Construct the full URL
    const url = `explore?${params.toString()}`
    return url
  }

  function onRedirectToShotExplorer (level) {
    const step = 0.2
    const minQuality = step * level
    const maxQuality = minQuality + step

    const url = createExploreUrl(minQuality.toFixed(1), maxQuality.toFixed(1), playerIdx)
    scrollToTopOfMain()
    navigate(`/video/${vid}/${url}`)
  }

  return (
    <ShotsDialogContainer>
      <div className='head'>
        <p>All shots</p>
        <IconButton className='close-button' onClick={onClose} title='Close'>
          <CloseIcon className='close-icon' />
        </IconButton>
      </div>
      <p className='player-name'>{playerName}</p>
      <div className='content'>
        {Object.keys(shotQualityLevels).reverse().map(level => {
          if (!playerShots[level].count) return null

          return (
            <Tooltip key={level} disabled={isMobile} title={`Click to view all ${shotQualityLevels[level].label} shots in Shot Explorer`}>
              <div className='shot-group' onClick={() => onRedirectToShotExplorer(level)}>
                <VisibilityIcon style={{ color: shotQualityLevels[level].color }} />
                <p>{shotQualityLevels[level].label}</p>
                <p>{playerShots[level].count}</p>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </ShotsDialogContainer>
  )
}

function SinglePlayer (props) {
  const ref = useRef(null)
  const { playerShots, vid, aiEngineVersion, video, onClick } = props
  const { userData } = video

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  const playerName = userData.players[props.playerIdx]?.name || `Player ${props.playerIdx + 1}`
  const isRounded = props.playerIdx < 2

  const values = useMemo(() => {
    if (!playerShots) return []
    return [
      { value: playerShots[4].percentage, fillColor: shotQualityLevels[4].color },
      { value: playerShots[3].percentage, fillColor: shotQualityLevels[3].color },
      { value: playerShots[2].percentage, fillColor: shotQualityLevels[2].color },
      { value: playerShots[1].percentage, fillColor: shotQualityLevels[1].color },
      { value: playerShots[0].percentage, fillColor: shotQualityLevels[0].color }
    ].filter(value => value.value)
  }, [playerShots])

  if (!playerShots) {
    return `No data for ${playerName}`
  }

  return (
    <SinglePlayerContainer ref={ref} onClick={() => onClick(playerShots, props.playerIdx, ref)}>
      <BarChart
        width={139}
        sx={{ height: '250px' }}
        withoutLabels
        isValuesRounded={false}
        values={values}
        isVertical
      />
      <PlayerImage className={cls('img', `player${props.playerIdx + 1}`)} width={90} height={55} numberOfImages={4} imageWidth={25} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={props.playerIdx} text={playerName} onChange={onRenamePlayer(props.playerIdx)}>
        <Avatar width='35' initials={playerName.slice(0, 1)} round={isRounded} className={cls('avatar', !isRounded && 'square')} />
      </PlayerImage>
    </SinglePlayerContainer>

  )
}

export default function OverviewPlayerPerformanceSection (props) {
  const { shotsByQualityPerPlayer } = props
  const isMobile = useMobileDetect()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogData, setDialogData] = useState(null)
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 })

  const { game_data: gameData, rallies } = props.insights

  function handleSingleChartClick (playerShots, playerIdx, ref) {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()

      // Calculate position above the clicked element
      setDialogPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width
      })
      setDialogData({ playerShots, playerIdx })
      setDialogOpen(true)
    }
  }

  function onCloseDialog () {
    setDialogOpen(false)
  }

  const dialogDesktopStyle = {
    position: 'absolute',
    top: '50%', // Vertically center the dialog
    left: dialogPosition.left + (dialogPosition.width / 2), // Horizontally position based on clicked element
    transform: 'translateY(-50%) translateX(-50%)',
    margin: 0
  }

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='head'>
        <h2>Player Performance</h2>
        <ExperimentalIcon />
      </div>

      <div className='content'>
        <div className='team'>
          <SinglePlayer playerShots={shotsByQualityPerPlayer[0]} playerIdx={0} {...props} onClick={handleSingleChartClick} />
          <SinglePlayer playerShots={shotsByQualityPerPlayer[1]} playerIdx={1} {...props} onClick={handleSingleChartClick} />
        </div>
        <Counter
          className='column'
          totalRallies={rallies.length}
          kitchenRallies={gameData.kitchen_rallies}
          avgShotsPerRally={gameData.avg_shots}
          longestRally={gameData.longest_rally?.num_shots}
        />
        <div className='team'>
          <SinglePlayer playerShots={shotsByQualityPerPlayer[2]} playerIdx={2} {...props} onClick={handleSingleChartClick} />
          <SinglePlayer playerShots={shotsByQualityPerPlayer[3]} playerIdx={3} {...props} onClick={handleSingleChartClick} />
        </div>
      </div>
      <Dialog
        onBackdropClick={() => setDialogOpen(false)}
        open={dialogOpen}
        PaperProps={{
          style: !isMobile ? dialogDesktopStyle : {}
        }}
      >
        <ShotsDialog playerShots={dialogData?.playerShots} playerIdx={dialogData?.playerIdx} userData={props.video.userData} onClose={onCloseDialog} />
      </Dialog>
    </Container>
  )
}

const ShotsDialogContainer = styled('div')({
  ...column,
  padding: '16px 24px',
  backgroundColor: COLORS['neutral-050'],
  border: `1px solid ${COLORS['neutral-300']}`,
  '& .head': {
    ...row,
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '20px',
    fontWeight: 600
  },
  '& .player-name': {
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '-10px',
    color: COLORS['text-tertiary'],
    marginBottom: 20
  },
  '& .content': {
    ...column,
    gap: '8px',
    '& .shot-group': {
      ...row,
      width: '180px',
      cursor: 'pointer',
      padding: '8px 16px',
      backgroundColor: COLORS['neutral-100'],
      borderRadius: '8px',
      border: `1px solid ${COLORS['neutral-300']}`,
      gap: 8,
      transition: '300ms',
      '& p': {
        fontSize: '14px',
        fontWeight: 600,
        '&:last-child': {
          marginLeft: 'auto'
        }
      },
      '&:hover': {
        backgroundColor: COLORS['neutral-200']
      }
    }
  }

})

const SinglePlayerContainer = styled('div')({
  ...column,
  cursor: 'pointer',
  position: 'relative',
  gap: '30px',
  alignItems: 'center',
  '& .avatar': {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%) translateY(-80%)'
  }
})

const Container = styled('div')({
  ...column,
  gap: '15px',
  '& .head': {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  '& .content': {
    ...row,
    '& .team': {
      ...row,
      gap: '10px'
    }
  },
  '& .counter': {
    flexGrow: 1
  },
  '&.mobile': {
    '& .content': {
      ...column,
      gap: 40
    },
    '& .counter': {
      order: 0
    },
    '& .team': {
      order: 1,
      justifyContent: 'center'
    }
  }
})
