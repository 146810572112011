import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { styled } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageContext } from '../context'

import Divider from '@/assets/breadcrumb-divider.svg?react'
import PublicFolderIcon from '@/assets/icons/public-folder-icon.svg?react'
import ShareFolderIcon from '@/assets/icons/share-icon-outlined.svg?react'
import { SimpleButton } from '@/components/button/simple-button'
import { TextButton } from '@/components/button/text-button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'

export default function Breadcrumb () {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { fid } = useParams()
  const { uid } = useParams()
  const { folders, selectedFolder, setIsShareDialogOpen, isRootFolderPublic, isInPublicLibrary } = useContext(PageContext)
  const [selectedFolderObject, setSelectedFolderObject] = useState()
  const [parentFolderObject, setParentFolderObject] = useState()
  const [grandParentFolderObject, setGrandParentFolderObject] = useState()

  function onNavigateToFolder (fid = false) {
    let link = '/library/'

    if (isInPublicLibrary) {
      link += `public/${uid}/`
    }

    if (fid) {
      link += fid
    }
    navigate(link)
  }

  function renderRootFolderShareIcon (onClick) {
    if (isInPublicLibrary || fid) {
      return
    }

    return (
      <SimpleButton onClick={() => setIsShareDialogOpen({ root: { public: isRootFolderPublic } })}>
        {isRootFolderPublic ? <PublicFolderIcon /> : <ShareFolderIcon />}
      </SimpleButton>
    )
  }

  function renderShareIcon (props) {
    if (isInPublicLibrary || Number(fid) !== props.shareObject.fid) {
      return
    }

    return (
      <SimpleButton onClick={() => setIsShareDialogOpen(props.shareObject)}>
        {(props.shareObject.public || isRootFolderPublic) ? <PublicFolderIcon /> : <ShareFolderIcon />}
      </SimpleButton>
    )
  }

  useEffect(() => {
    const object = folders?.find(folder => folder.fid === Number(selectedFolder))

    setSelectedFolderObject(object || null)

    if (object?.parent) {
      const parentObject = folders?.find(folder => folder.fid === object.parent)
      setParentFolderObject(parentObject)

      if (parentObject?.parent) {
        const grandParentObject = folders?.find(folder => folder.fid === parentObject.parent)
        setGrandParentFolderObject(grandParentObject)
      } else {
        setGrandParentFolderObject(null)
      }
    } else {
      setParentFolderObject(null)
      setGrandParentFolderObject(null)
    }
  }, [folders, selectedFolder])

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='current-folder'>
        {parentFolderObject ? <SimpleButton width={32} height={32} size='large' className='root-icon' onClick={() => onNavigateToFolder()}><HomeRoundedIcon /></SimpleButton> : <TextButton onClick={() => onNavigateToFolder()}>Video Library</TextButton>}
        {renderRootFolderShareIcon()}
      </div>
      {selectedFolderObject && <Divider />}
      {grandParentFolderObject && (
        <>
          ...
          <Divider />
        </>
      )}
      {parentFolderObject && (
        <>
          <div className='current-folder'>
            <SimpleButton onClick={() => onNavigateToFolder(parentFolderObject.fid)}> <p>{parentFolderObject?.name}</p></SimpleButton>
            {renderShareIcon({ shareObject: parentFolderObject })}
          </div>
          <Divider />
        </>
      )}
      {selectedFolderObject && (
        <div className='current-folder'>
          <SimpleButton>
            <p>{selectedFolderObject?.name}</p>
          </SimpleButton>
          {renderShareIcon({ shareObject: selectedFolderObject })}
        </div>
      )}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px',
  color: COLORS.black,
  fontSize: '24px',
  fontWeight: 600,
  '& .root-icon svg': {
    width: '32px',
    height: '32px',
    color: COLORS['neutral-700']
  },
  '& .current-folder': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& button': {
      maxWidth: '100%'
    },
    '& p': {
      fontSize: '24px',
      textAlign: 'start',
      lineHeight: '26px',
      textTransform: 'none',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '100%'
    }
  },
  '&.mobile': {
    fontSize: '20px',
    maxWidth: '100%',
    '& .current-folder': {
      maxWidth: '90%',
      '& p': {
        fontSize: '20px'
      }
    }

  }
})
