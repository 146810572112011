import CloseIcon from '@mui/icons-material/Close'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Button, TextField } from '@mui/material'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container } from './container'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { tagUser } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'

/**
 * AddUserPhonePopup component
 *
 * @exports
 * @param props {object} { open, anchorEl, onClose, playerName }
 * @returns {React.ReactElement}
 */
export const AddUserPhonePopup = (props) => {
  const isMobile = useMobileDetect()
  const dispatch = useDispatch()
  const openSnackbar = useSnackbar()
  const { open, anchorEl, playerName, vid, playerIdx, aiEngineVersion } = props
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (!open) {
      setPhone('')
    }
  }, [open])

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  const onClose = () => props.onClose()

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const sendSMSInvite = async () => {
    try {
      await dispatch(tagUser({ vid, playerIdx, name: playerName, addr: phone }))
      props.onClose({ success: true })
    } catch (error) {
      openSnackbar('An error occurred while tagging the user. Please try again', 'error')
    } finally {
      props.onClose()
    }
  }

  const onPhoneChange = (event) => {
    const value = event.target.value
    setPhone(value)
  }

  function validatePhoneNumber (phoneNumber) {
    // Regular expression for 10-digit phone numbers
    const tenDigitPattern = /^[0-9]{10}$/
    // Regular expression for international phone numbers starting with "+"
    const internationalPattern = /^\+[0-9]+$/

    if (tenDigitPattern.test(phoneNumber)) {
      return true
    } else return internationalPattern.test(phoneNumber)
  }

  const onInputKeyDown = (event) => {
    const { key } = event
    if (key === 'Enter') {
      sendSMSInvite()
    }
  }

  return (
    <Container
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement='bottom-start'
      className={clsx([{ mobile: isMobile }])}
    >
      <div className='header'>
        <div className='title-section'>
          <div className='avatar'>P</div>
          <span className='title'>{playerName}</span>
        </div>
        <CloseIcon className='close-icon' onClick={onClose} />
      </div>
      <div className='content'>
        <div className='player-images'>
          {playerImages.map((a, i) => (
            <img key={i} src={normalizeUrl(a)} alt='' />
          ))}
        </div>
        <div className='control-section'>
          <TextField
            id='phone'
            autoFocus
            value={phone}
            onChange={onPhoneChange}
            placeholder='Enter Phone Number'
            InputProps={{
              onKeyDown: onInputKeyDown,
              startAdornment: <SmsOutlinedIcon />
            }}
          />
          <Button disabled={!validatePhoneNumber(phone)} onClick={sendSMSInvite} variant='contained'>
            Send SMS Invite
          </Button>
        </div>
      </div>
    </Container>
  )
}
