import { styled } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { PlayerStatsContext } from '../index'

import ShotAccuracy from './shot-accuracy'

import { Avatar } from '@/components/avatar'
import { Percentile } from '@/components/percentile'
import { PlayerImage } from '@/components/player-image'
import { TagPlayer } from '@/components/tag-player'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import { calculatePercentile } from '@/utils/helpers/insights'

function progressBarColor (value) {
  return value > 50 ? COLORS['primary-500'] : COLORS.player3
}

export default function StatsHeadInfo (props) {
  const { vid } = useParams()
  const isMobile = useMobileDetect()
  const context = useContext(PlayerStatsContext)
  const insights = context?.insights
  const playerStats = context?.playerStats
  const workflowContext = context?.workflow
  const playerIdx = props.playerIdx

  const aiEngineVersion = workflowContext?.aiEngineVersion

  if (!insights || !aiEngineVersion) {
    return null
  }

  const avgServePercentile = calculatePercentile(round(playerStats[playerIdx].serve_avg_speed))
  const avgDrivePercentile = calculatePercentile(round(playerStats[playerIdx].drive_avg_speed), 'drive')

  return (
    <Container className={`player${playerIdx + 1} ${isMobile && 'mobile'}`}>
      <PlayerImage className='player-image' vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={playerIdx} text={props.player.name || `Player ${playerIdx + 1}`} onChange={props.onRenamePlayer(playerIdx)} fit />
      <div className='info'>
        <div className='info-head'>
          <TagPlayer
            vid={vid}
            playerIdx={playerIdx}
            playerName={props.player.name}
            aiEngineVersion={aiEngineVersion}
            sx={{ minWidth: '160px', gap: '16px' }}
          >
            <Avatar className='avatar' width='35' initials='P' round={playerIdx < 2} />
          </TagPlayer>
        </div>
        <ShotAccuracy total={insights.player_data[playerIdx].shot_count} stats={playerStats[playerIdx].shotAccuracy} />
        <div className='percentiles'>
          <div className='percentile'>
            <div className='percentile-head'>
              <strong>Serve</strong>
              <p>Percentile</p>
            </div>
            <Tooltip title='Shows the player’s average serve speed for the game, compared to a 30 mph benchmark (50th percentile)'>
              <div className='statistic'>
                <p>Avg Serve:</p>
                <strong>{round(playerStats[playerIdx].serve_avg_speed)}mph</strong>
                <Percentile
                  value={avgServePercentile}
                  color={progressBarColor(avgServePercentile)}
                />
              </div>
            </Tooltip>
            <div className='statistic'>
              <p>Top Serve:</p>
              <strong>{round(playerStats[playerIdx].serve_fastest_speed)}mph</strong>
            </div>
          </div>
          <div className='percentile'>
            <div className='percentile-head'>
              <strong>Drive</strong>
              <p>Percentile</p>
            </div>
            <Tooltip title='Shows the player’s average drive speed for the game, compared to a 25 mph benchmark (50th percentile)'>
              <div className='statistic'>
                <p>Avg Drive:</p>
                <strong>{round(playerStats[playerIdx].drive_avg_speed)}mph</strong>
                <Percentile
                  value={avgDrivePercentile}
                  color={progressBarColor(avgDrivePercentile)}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  padding: '32px',
  gap: '32px',
  color: COLORS['neutral-800'],
  '& .info': {
    ...column,
    flex: 1,
    gap: 20,
    '& .info-head': {
      ...row,
      gap: 16,
      '& span': {
        color: COLORS['neutral-800']
      },
      '& .player-name': {
        fontWeight: 700,
        lineHeight: '133%',
        color: COLORS['neutral-800'],
        '& .editing': {
          marginBottom: 1.89
        }
      }
    },
    '& .total-shots': {
      fontSize: 20,
      color: COLORS['neutral-800'],
      lineHeight: '31px'
    },
    '& .percentiles': {
      ...row,
      gap: 50,
      fontSize: 14,
      color: COLORS['neutral-800'],
      '& .percentile': {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '14px',
        '& .percentile-head': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${COLORS['neutral-200']}`
        }
      },
      '& .statistic': {
        ...row,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        '& .percentile': {
          width: 90
        },
        fontSize: '12px',
        '& strong': {
          marginRight: 5,
          marginLeft: 10
        }
      }
    }
  },
  '&.mobile': {
    ...column,
    padding: '15px 15px 25px 15px',
    '& .player-image': {
      width: '100%'
    },
    '& .percentiles': {
      ...column

    }
  }
})
