import { Box, CircularProgress, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'

import { now } from '@/utils'
import cls from '@/utils/classnames'

/**
 * CircularLoadingIndicator component displays a circular loading indicator with optional label and optional fake progress.
 * @param {Object} props - Component props.
 * @param {string} [props.label] - The label to display below the loading indicator.
 * @param {boolean} [props.fullscreen] - Whether the loading indicator should be displayed fullscreen.
 * @param {number} [props.startEpoch] - when progress started (optional)
 * @param {number} [props.estimatedCompletionEpoch] - when we expect progress to finish (optional)
 * @param {number} [props.estimatedSecsToFinish] - how long until we expect progress to be done (optional)
 * @returns {JSX.Element} A JSX element representing the CircularLoadingIndicator.
 */

export function CircularLoadingIndicator (props) {
  const [startEpoch] = useState(props.startEpoch ?? now())
  const [estimatedEndEpoch] = useState(
    props.estimatedCompletionEpoch ??
     (props.estimatedSecsToFinish
       ? startEpoch + props.estimatedSecsToFinish
       : undefined))
  const [percentDone, setPercentDone] = useState(0)

  useEffect(() => {
    const estimatedTotalSecs = estimatedEndEpoch - startEpoch
    if (!estimatedEndEpoch || estimatedTotalSecs <= 0) {
      setPercentDone(undefined)
      return
    }
    const timer = setInterval(() => {
      const estimatedSecsLeft = estimatedEndEpoch - now()
      const percentDone = 1 - Math.max(0, estimatedSecsLeft / estimatedTotalSecs)
      if (percentDone >= 1) {
        clearInterval(timer)
      }
      setPercentDone(100 * percentDone)
    }, 100) // Update every 100ms

    return () => {
      clearInterval(timer)
    }
  }, [startEpoch, estimatedEndEpoch])

  const isDeterminate = estimatedEndEpoch !== undefined && percentDone !== 100
  return (
    <Container className={cls(props.fullscreen && 'fullscreen', props.className)}>
      <Box sx={{ position: 'relative', display: 'flex', width: '100%', justifyContent: 'center' }}>
        <CircularProgress
          variant={`${isDeterminate ? 'determinate' : 'indeterminate'}`}
          value={percentDone}
          size={props.size}
        />
        {isDeterminate &&
          <Box
            className='progress-label'
          >
            <Typography variant='caption' component='div' color='text.secondary'>
              {`${Math.round(percentDone)}%`}
            </Typography>
          </Box>}
      </Box>
      <Typography variant='caption' component='div' color='text.secondary' className='label'>
        {props.label}
      </Typography>
    </Container>
  )
}

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '&.fullscreen': {
    minHeight: 'calc(100vh - 48px)'
  },
  '& .label': {
    marginTop: 10
  },
  '& .progress-label': {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

}))
