import SaveIcon from '@mui/icons-material/Save'
import { Box, OutlinedInput } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { validate } from 'email-validator'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { TextButton } from '../button/text-button'

import { Container } from './container'

import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { selectLoggedInUser, signOut, updateCurrentUser, useLoggedInUserCredentials } from '@/store/auth'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import cls from '@/utils/classnames'
import { normalizeErrorMessage } from '@/utils/email'
import { getAuth } from '@/utils/firebase'
import { getNativeAppInfo, isInMobileAppWebview, isVersionedFeatureAllowed, notifyDebugLogsRequested, promptToEnablePushNotifications } from '@/utils/mobile-app-communication'

export function Account () {
  const isMobile = useMobileDetect()
  const dispatch = useDispatch()
  const user = useSelector(selectLoggedInUser)
  const [newEmail, setNewEmail] = useState(user.email)
  const [accountEmail, setAccountEmail] = useState(user.email)
  const [userData, setUserData] = useState({
    displayName: '',
    marketing: user?.notifications?.marketing,
    videoDone: user?.notifications?.videoDone
  })
  const [passwordSent, setPasswordSent] = useState(false)
  const openSnackbar = useSnackbar()

  const settingsChanged = userData.marketing !== user?.notifications?.marketing || userData.videoDone !== user?.notifications?.videoDone || userData.displayName !== user?.displayName
  const saveChangesDisabled = !settingsChanged

  const updateUser = (name, value) => {
    setUserData((prev) => ({ ...prev, [name]: value }))
  }

  const saveChanges = () => {
    dispatch(updateCurrentUser({
      displayName: userData.displayName,
      notifications: { marketing: userData.marketing, videoDone: userData.videoDone }
    }))
  }

  const { toggleZendeskWidget } = useZendesk()

  const doSendPasswordResetEmail = () => {
    if (accountEmail) {
      sendPasswordResetEmail(getAuth(), accountEmail, {
        url: window.location.href
      }).then(() => {
        setPasswordSent(true)
      }).catch((err) => {
        const normalizedError = normalizeErrorMessage(err)
        openSnackbar(normalizedError, 'error')
      })
    }
  }

  const onEmailKeyUp = (e) => {
    if (e.key === 'Enter') {
      doSendPasswordResetEmail()
    }
  }

  useEffect(() => {
    if (user) {
      const { displayName, notifications } = user
      // Load prev as user is missing notification in one of the updates - so preserve previous notifications
      setUserData((prev) => ({
        ...prev,
        displayName: displayName ?? '',
        ...notifications
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.displayName, user.notifications])

  useEffect(() => {
    // Reset flag on re-render
    setPasswordSent(false)
  }, [])

  const nativeAppInfo = getNativeAppInfo()
  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='title'>Account
        {isMobile && (
          <TextButton
            className='signOut'
            onClick={signOut}
          >
            Sign out
          </TextButton>
        )}
      </div>
      {isInMobileAppWebview() && (
        <div style={{ color: '#000', textAlign: 'right', width: '100%' }}>
          <i>Need help?</i>
          <Button
            className='sendEmailButton'
            onClick={toggleZendeskWidget}
            style={{ marginLeft: '0.5rem', position: 'relative', top: '-6px', width: 'auto' }}
          >
            Contact Support
          </Button>
        </div>
      )}
      {isInMobileAppWebview() && !isProd && isVersionedFeatureAllowed('2.0.5') && (
        <div style={{ color: '#000', textAlign: 'right', width: '100%' }}>
          <Button
            className='sendEmailButton'
            onClick={notifyDebugLogsRequested}
            style={{ marginLeft: '0.5rem', position: 'relative', top: '-6px', width: 'auto' }}
          >
            Get Debug Logs
          </Button>
        </div>
      )}

      <div className='inputSection'>
        <div className={cls('textField', 'name')}>
          <div className='label'>Full Name</div>
          <TextField
            variant='outlined'
            classes={{ root: 'muiRoot' }}
            value={userData.displayName}
            onChange={(evt) => updateUser('displayName', evt.target.value)}
            placeholder='Full Name'
            fullWidth
          />
        </div>
      </div>
      <div className='inputSection'>
        <div className={cls('textField', 'email')}>
          <div className='label'>Email</div>
          <TextField
            variant='outlined'
            classes={{ root: 'muiRoot' }}
            value={newEmail || ''}
            onChange={(evt) => setNewEmail(evt.target.value)}
            placeholder='Email'
            disabled
          />
        </div>
      </div>
      <Divider className='divider' />
      <div className='resetPasswordSection'>
        <div className='title'>Reset your password</div>
        {passwordSent
          ? <div className='subTitle'>Reset password link has been sent to: {accountEmail}</div>
          : (
            <>
              <div className='subTitle'>To reset your password, enter the email associated with your account.</div>
              <div className='associatedEmailSection'>
                <div className={cls('textField', 'email')}>
                  <div className='label'>Email</div>
                  <TextField
                    variant='outlined'
                    classes={{ root: 'muiRoot' }}
                    value={accountEmail || ''}
                    onChange={(evt) => setAccountEmail(evt.target.value)}
                    inputProps={{
                      onKeyUp: onEmailKeyUp
                    }}
                  />
                </div>
                <Button
                  disabled={!validate(accountEmail)}
                  classes={{ root: 'sendEmailButton', disabled: 'disabled' }}
                  onClick={doSendPasswordResetEmail}
                >
                  Send Email
                </Button>
              </div>
            </>
            )}
      </div>
      <Divider className='divider' />

      <div className='emailNotificationsSection'>
        <FormControlLabel
          classes={{ root: 'formControl', label: 'label' }}
          control={
            <Checkbox
              className='checkbox'
              color='default'
              checked={userData.videoDone}
              onChange={(evt) => {
                updateUser('videoDone', evt.target.checked)
              }}
            />
          }
          label='I would like to receive email notifications after video processing is completed'
        />
      </div>
      {userData.videoDone && !user?.pushToken && (
        <Button
          variant='outlined'
          className='sendEmailButton'
          onClick={promptToEnablePushNotifications}
          endIcon={<SaveIcon className='icon' />}
        >
          Enable Push Notifications
        </Button>
      )}
      <div className='productUpdatesSection'>
        <FormControlLabel
          classes={{ root: 'formControl', label: 'label' }}
          control={
            <Checkbox
              className='checkbox'
              color='default'
              checked={userData.marketing}
              onChange={(evt) => {
                updateUser('marketing', evt.target.checked)
              }}
            />
          }
          label='I would like to receive product update emails from PB.Vision'
        />
      </div>

      <div className='deleteAccount'>
        {isInMobileAppWebview() && (
          <DeletionRequest />
        )}
        {!isInMobileAppWebview() && (
          <>If you would like to delete your account, please<TextButton className='contactSupport' onClick={toggleZendeskWidget}>contact support.</TextButton></>
        )}
      </div>

      <Button
        variant='outlined'
        className={cls('saveChangesButton', saveChangesDisabled && 'disabled')}
        onClick={saveChanges}
        disabled={saveChangesDisabled}
        endIcon={<SaveIcon className='icon' />}
      >
        Save Changes
      </Button>
      <Box sx={{ fontSize: '0.8rem', marginTop: '2rem' }}>
        {isProd ? 'Live' : 'Test'}{' '}
        Version {import.meta.env.VITE_RELEASE?.substring(0, 8) ?? 'unset'}
        {isInMobileAppWebview() && (
          <> (App v{nativeAppInfo.version.major}.{nativeAppInfo.version.minor}.{nativeAppInfo.version.patch}${nativeAppInfo.build ? ` build ${nativeAppInfo.build}` : ''})</>
        )}
      </Box>
    </Container>
  )
}

function DeletionRequest () {
  const navigate = useNavigate()
  const { token, uid } = useLoggedInUserCredentials()
  const [step, setStep] = useState('not-started')
  const [deletionCode, setDeletionCode] = useState('')
  const headers = {
    'x-uid': uid,
    'x-token': token
  }

  async function startDeletion () {
    setStep('requested')
    setDeletionCode('')
    await window.fetch(`${import.meta.env.VITE_API_SERVER}/user/delete/request`, {
      method: 'POST',
      headers
    })
  }

  async function confirmDeletion () {
    setStep('deleting')
    try {
      const resp = await window.fetch(`${import.meta.env.VITE_API_SERVER}/user/delete/confirm`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ deletionCode })
      })
      if (resp.ok) {
        setStep('deleted')
        await signOut()
        navigate('/')
      } else {
        console.error('deletion failed due to HTTP error', resp.status)
        setStep('error')
      }
    } catch (err) {
      console.error(err)
      setStep('error')
    }
  }

  if (step === 'not-started') {
    return (
      <>
        If you would like to delete your account, please{' '}
        <TextButton className='contactSupport' onClick={startDeletion}>click here.</TextButton>
      </>
    )
  } else if (step === 'requested') {
    return (
      <>
        <Box>
          We sent a code to your email to verify your deletion request. Enter
          the code here within 15 minutes to permanently delete your account:
        </Box>
        <Box>
          <OutlinedInput
            size='small'
            placeholder='Deletion Code'
            value={deletionCode}
            onChange={e => setDeletionCode(e.target.value)}
          />
          <Button
            variant='outlined'
            onClick={confirmDeletion}
            disabled={deletionCode.length !== 8}
          >
            Confirm Account Deletion
          </Button>
        </Box>
      </>
    )
  } else if (step === 'deleting') {
    return 'Deleting...'
  } else if (step === 'deleted') {
    return 'Deleted!'
  } else if (step === 'error') {
    return 'Deletion failed. Please try again or contact support for help.'
  }
}
