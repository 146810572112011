import { styled } from '@mui/material'

import { ExperimentalIcon } from '@/components/experimental-icon'
import { Tooltip } from '@/components/tooltip'
import COLORS from '@/utils/colors'

const Container = styled('div')({
  color: COLORS['neutral-800'],
  fontSize: 16,
  fontWeight: 700,
  '& .sa-progress': {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    color: '#fff',
    fontSize: 12,

    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      // textAlign: 'center',
      lineHeight: '24px',
      whiteSpace: 'nowrap',
      '& > span': {
        flexGrow: 1,
        textAlign: 'center'
      }
    },
    '& .sa-in': {
      backgroundColor: COLORS['primary-500']
    },
    '& .sa-net': {
      backgroundColor: COLORS['Players/Ball-machine']
    },
    '& .sa-out': {
      backgroundColor: COLORS['Quality_scale-Poor']
    }
  },
  '& .sa-title': {
    display: 'flex',
    flexDirection: 'row',
    height: 24,
    marginBottom: 4,
    '& .sa-total': {
      marginLeft: 'auto'
    },
    '& svg': {
      color: COLORS['Quality_scale-Fair']
    }
  }
})

const getLabel = (text, size) => size >= 12 ? `${text} (${size}%)` : size >= 5 ? text : ''

export default function ShotAccuracy (props) {
  const stats = props.stats
  return (
    <Container>
      <div className='sa-title'>
        Shot Accuracy
        <ExperimentalIcon />
        <div className='sa-total'>Total Shots: {props.total}</div>
      </div>
      <div className='sa-progress'>
        <div className='sa-in' style={{ width: `${stats.in}%` }}>
          <Tooltip title={`In (${stats.in}%)`}>
            {getLabel('In', stats.in)}
          </Tooltip>
        </div>
        <div className='sa-net' style={{ width: `${stats.net}%` }}>
          <Tooltip title={`Net (${stats.net}%)`}>
            {getLabel('Net', stats.net)}
          </Tooltip>
        </div>
        <div className='sa-out' style={{ width: `${stats.out}%` }}>
          <Tooltip title={`Out (${stats.out}%)`}>
            {getLabel('Out', stats.out)}
          </Tooltip>
        </div>
      </div>
    </Container>
  )
}
