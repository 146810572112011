/**
 * Custom hook that calculates shot quality percentages and counts for each player based on their shot insights.
 *
 * @returns {Object} An object containing the method `getShotQualityForAllShotsPerPlayer`.
 *
 * @function getShotQualityForAllShotsPerPlayer
 * @description Calculates the shot quality percentages and shot counts for each player from the provided insights.
 *
 * @param {Object} insights - The insights object.
 * @property {Object} insights.rallies - The rallies data containing moments and shots.
 *
 * @returns {Object} qualityPerPlayerObj - An object where each key is a player ID, and each value contains shot quality levels with the percentage and count of shots at each quality level.
 * Example:
 * {
 *   0: { 0: { percentage: '1.408', count: 1 }, 1: { percentage: '2.817', count: 2 }, ... },
 *   1: { 0: { percentage: '3.226', count: 2 }, 1: { percentage: '0.000', count: 0 }, ... },
 *   ...
 * }
 */

export function usePlayersShotQuality () {
  const QUALITY_LEVELS = 5
  const qualityPerPlayerObj = {}

  function getShotQualityForPlayers (insights) {
    const { rallies } = insights
    const ralliesArray = rallies

    const shotsPerPlayer = {}

    ralliesArray.forEach((rally) => {
      const shotsArray = rally.shots
      shotsArray.forEach((shot) => {
        if (shot) {
          const playerIdx = shot.player_id
          if (playerIdx !== undefined) {
            if (!shotsPerPlayer[playerIdx]) {
              shotsPerPlayer[playerIdx] = []
            }
            shotsPerPlayer[playerIdx].push(shot.quality)
          }
        }
      })
    })

    return shotsPerPlayer
  }

  function calculateShotQualityPercentage (countObj, totalShots) {
    const percentageObj = {}
    for (const level in countObj) {
      if (!percentageObj[level]) {
        percentageObj[level] = {}
      }
      percentageObj[level].percentage = ((countObj[level] / totalShots) * 100).toFixed(3)
      percentageObj[level].count = countObj[level]
    }
    return percentageObj
  }

  function shotQualityCount (shots) {
    const counterObj = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0
    }

    shots.forEach(shot => {
      if (shot.overall === undefined) return
      const index = Math.min(Math.floor(shot.overall * QUALITY_LEVELS), QUALITY_LEVELS - 1)
      counterObj[index]++
    })

    return counterObj
  }

  function getShotQualityForAllShotsPerPlayer (insights) {
    const shotsPerPlayer = getShotQualityForPlayers(insights)

    Object.keys(shotsPerPlayer).forEach((playerId) => {
      const shotCount = shotQualityCount(shotsPerPlayer[playerId])

      const totalShots = shotsPerPlayer[playerId].length

      const shotPercentages = calculateShotQualityPercentage(shotCount, totalShots)
      qualityPerPlayerObj[playerId] = shotPercentages
    })

    return qualityPerPlayerObj
  }

  return { getShotQualityForAllShotsPerPlayer }
}
