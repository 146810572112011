import CloseIcon from '@mui/icons-material/Close'
import MuiDialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

import DiscordLogo from '@/assets/icons/discord-logo.svg?react'
import { Button } from '@/components/button'
import { SimpleButton } from '@/components/button/simple-button'
import { useGetVideo } from '@/store/video'
import COLORS from '@/utils/colors'
import { downloadOrOpenLink } from '@/utils/download'

const Dialog = styled(MuiDialog)({
  '.MuiPaper-root': {
    color: COLORS['neutral-700'],
    height: 440,
    maxWidth: 756,
    minWidth: 756,
    padding: '32px 40px'
  },
  '& h6': {
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    '& .close-btn': {
      color: COLORS['neutral-500'],
      marginLeft: 'auto'
    }
  },
  '& .sub-title': {
    fontSize: 16,
    fontWeight: 300
  },
  '& .download-table': {
    border: '1px solid  rgba(99, 107, 116, 0.30)',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    fontWeight: 400,
    margin: '27px 0',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '&.row-1': {
        backgroundColor: COLORS['neutral-100']
      },
      '&:not(:last-of-type)': {
        borderBottom: '1px solid  rgba(99, 107, 116, 0.30)'
      },
      '& > div': {
        padding: '8px 12px'
      },
      '& .file-name': {
        display: 'flex',
        fontSize: 16,
        fontWeight: 700,
        maxWidth: 120,
        minWidth: 120
      },
      '& .desc': {
        display: 'flex',
        flexGrow: 1
      },
      '& .download button': {
        border: `1px solid ${COLORS['neutral-300']}`,
        borderRadius: 8,
        '&:hover': {
          backgroundColor: 'unset'
        }
      }
    }
  },
  '& .footer': {
    fontSize: 14
  },
  '& .discord-logo': {
    background: '#5865F2',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    float: 'left',
    marginRight: 24,
    height: 48,
    width: 149
  },
  '& a': {
    color: COLORS['primary-500'],
    cursor: 'pointer'
  }
})

const tableData = [
  {
    file: 'stats.json',
    text: (
      <span>
        A comprehensive set of advanced statistics that goes beyond what&apos;s currently available in our insights view.&nbsp;
        <a href='https://pb.vision/post/interpreting-pb-visions-advanced-stats' target='_blank' rel='noreferrer'>Learn&nbsp;more</a>
      </span>
    )
  },
  {
    file: 'insights.json',
    text: (
      <span>
        The data used to power the PB Vision app.&nbsp;
        <a href='https://pbv-public.github.io/insights' target='_blank' rel='noreferrer'>Learn&nbsp;more</a>
      </span>
    )
  },
  {
    file: 'cv.json',
    text: (
      <span>
        The low-level computer vision data from which everything else is derived.&nbsp;
        <a href='https://pb-vision.github.io/schemas-cv' target='_blank' rel='noreferrer'>Learn&nbsp;more</a>
      </span>
    )
  }
]

const DownloadRawDataModal = ({ vid, open, onClose }) => {
  const videoDetails = useGetVideo(vid)
  const aiEngineVersion = videoDetails?.data?.workflows?.[0]?.aiEngineVersion

  const download = (file) => {
    let url
    let forceDirectLink = false
    if (file === 'stats.json' || file === 'insights.json') {
      url = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/${file}`
    } else if (file === 'cv.json') {
      url = `${import.meta.env.VITE_API_SERVER}/video/data/cv?vid=${vid}`
      // cv.json has cors limitation so we need to use direct url instead of trying to download the file first
      forceDirectLink = true
    }
    downloadOrOpenLink(url, file, forceDirectLink)
  }

  // This is needed to prevent click from escaping into the video thumb
  // and triggering the navigation to the video page
  const prevent = (e) => {
    e.stopPropagation()
  }

  return (
    <Dialog open={Boolean(open)} onClose={onClose} onClick={prevent}>
      <h6>
        Download Raw Data
        <SimpleButton className='close-btn' onClick={onClose}>
          <CloseIcon />
        </SimpleButton>
      </h6>
      <div className='sub-title'>
        Want to dive deeper into your data? You&apos;ve come to the right place! Here you can download additional data you&apos;d like to explore on your own. <a href='https://pb.vision/post/pb-vision-open-data-program' target='_blank' rel='noreferrer'>Learn more</a>
      </div>
      <div className='download-table'>
        {tableData.map((row, indx) => (
          <div className={`row-${indx}`} key={row.file}>
            <div className='file-name'>{row.file}</div>
            <div className='desc'>{row.text}</div>
            <div className='download'>
              <Button variant='contained' color='primary' onClick={() => download(row.file)} disableElevation>
                Download
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className='footer'>
        <a href='https://discord.gg/4QDcaTCEUh' className='discord-logo' target='_blank' rel='noreferrer'>
          <DiscordLogo />
        </a>
        Have questions? Recommendations? Want to talk about all this with fellow data enthusiasts? Please come join us on Discord!
      </div>
    </Dialog>
  )
}

export default DownloadRawDataModal
