/**
 * This slice tracks the progress of a video file being uploaded. This includes
 * tracking videos until they are claimed by a logged in user.
 *
 * This does not include post-upload processing progress. That is part of the
 * video, not part of the upload.
 */
import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { newUnseenAnonymousVideo } from './anonymous'
import { apisSlice } from './apis'
import { getLoggedInUserToken, isAnonymousUserId, useIsLoggedIn, useUserId, userChanged } from './auth'
import { createLocalStorageState } from './local-storage-state'
import { watchVideoProgress } from './processing'

import { assert, millinow, now, sleep } from '@/utils'
import { getFileExt } from '@/utils/file'
import pick from '@/utils/pick'
import { getVideoMeta } from '@/utils/video'

/** @typedef {string} vid the unique ID of a video on the server */

export const sliceName = 'upload'
const { preloadedState, saveState, useSliceState } = createLocalStorageState({
  sliceName,
  defaultState: {
    savable: {
      /**
       * Recently successfully uploaded VIDs are stored here until we start
       * seeing them in the library API (usually that takes just a few seconds,
       * but we want to make sure the new videos continuously show up in the
       * library)
       *
       * @type {Array<vid>}
       */
      recentlyCompletedUploadVIDs: [],

      /**
       * Recently successfully uploaded VIDs don't have the fid property which is
       * used to determine which folder video should be placed in. For the mobile devices
       * newUploadDone returns fid beside the fid,
       * so we can use them to determine where to place
       * that video. For videos uploaded via the browser, the `makeUploadId`
       * function returns the `fid` property.
       * Until the library API be ready to returns them.
       *
       * @typedef {object} RecentlyUploadedVIDsFoldersObj
       * @property {string} vid video's ID
       * @property {number} fid video's folder ID
       *
       * @type {Array<RecentlyUploadedVIDsFoldersObj}
       */
      recentlyUploadedVIDsFolders: [],

      /**
       * Videos that were uploaded anonymously are remembered here until the
       * user logs in and we successfully claim them (keys are vids).
       *
       * @typedef {object} AnonymousUploadNotYetAuthoritativelyClaimed
       * @property {boolean} isPreliminarilyClaimed whether the video has been preliminarily claimed
       * @property {boolean} isUploaded whether the video is done uploading (successfully)
       * @property {boolean} isClaiming whether the video is being claimed authoritatively (in the middle of the API call)
       */
      /** @type {Map<vid, AnonymousUploadNotYetAuthoritativelyClaimed>} */
      anonymousUploadsNotYetAuthoritativelyClaimed: {},

      /**
       * Track the  state of completed uploads not yet cleared (failed or not).
       * The first upload to end is listed first in this array, etc.
       *
       * @typedef {object} EndedUpload
       * @extends UploadInfo
       * @property {string} [error] describes the error IF one occurred
       */
      /** @type {Array<EndedUpload>} */
      endedUploads: []
    },
    ephemeral: {
      /**
       * Track the upload popup state, if all uploads were finished user can close the popup until he uploads the next video
       * @type {boolean}
       */
      popupClosed: false,
      /**
       * Tracks the result of the first claim anonymously uploaded video API called.
       * @typedef {object} AnonymousVideoClaimResult
       * @property {boolean} isFirstVideo whether this was (logged in) user's first video upload ever
       * @property {boolean} [isPasswordSet] whether the user's had a password set (only provided for preliminary claims)
       */
      /** @type {AnonymousVideoClaimResult} */
      claimResult: null,

      /**
       * Track the state of uploads which are in progress in this session
       *   - reloading the page while they're in progress causes them to be lost!
       *   - they are not tracked across multiple tabs either
       * The first upload queued is listed first in this array, etc.
       *
       * @typedef {object} UploadInfoWithUploadProgress
       * @extends UploadInfo
       * @property {int} numBytesDone number of bytes done
       * @property {int} [estimatedUploadFinishEpoch] when we believe we'll finish, if we have an estimate
       * @property {int} [startEpoch] set once the upload is actually uploaded (we only upload one video at a time)
       */
      /** @type {Array<UploadInfoWithUploadProgress>} */
      uploadsInProgress: [],
      /**
       * Tracks if last user upload had errors while extracting meta information
       * @type {boolean} True / false
      */
      uploadFormatError: false
    }
  },
  customizePreloadedStateFunction: preloadedState => {
    // anything which was still uploading does not need to be claimed since it
    // did not finish uploading (though it wouldn't hurt to claim them)
    const pendingClaims = preloadedState.anonymousUploadsNotYetAuthoritativelyClaimed
    for (const vid of Object.keys(pendingClaims)) {
      pendingClaims[vid].isClaiming = false
      if (!pendingClaims[vid].isUploaded) {
        delete pendingClaims[vid]
      }
    }
    return preloadedState
  }
})

const inMemoryState = {
  /**
   * Tracks file objects being uploaded. These can't be serialized to JSON so
   * they cannot be stored in Redux.
   * @type {Map<vid, File>}
   */
  files: {},
  // abort controller for the active upload (if there is an active upload)
  abortController: null
}

const uploadsSlice = createSlice({
  name: sliceName,
  initialState: preloadedState,
  reducers: {
    uploadQueued: (state, action) => {
      // ensure this vid hasn't already been used (if so, it's a bug in our code)
      const uploadInfoWithProgress = action.payload
      const { vid } = uploadInfoWithProgress
      assert(!findVidInArray(state.uploadsInProgress, vid),
        'vid already in the in progress list')
      assert(!findVidInArray(state.endedUploads, vid),
        'vid already in the ended list')
      state.uploadsInProgress.push(uploadInfoWithProgress)
      if (isAnonymousUserId(uploadInfoWithProgress.uploaderUserId)) {
        state.anonymousUploadsNotYetAuthoritativelyClaimed[vid] = {
          isPreliminarilyClaimed: false,
          isUploaded: false
        }
      }
      // Make the popup visible on video upload
      state.popupClosed = false
      saveState(state)
    },
    uploadStarted: (state, action) => {
      // this vid should've been in the in progress list
      const { startEpoch, vid } = action.payload
      const found = findVidInArray(state.uploadsInProgress, vid)
      assert(found, 'cannot start an upload not in the in progress list')
      assert(!found.startEpoch, 'already started upload')
      found.startEpoch = startEpoch
      saveState(state)
      trackUploadAnalyticsEvent('started', found)
    },
    uploadProgressed: (state, action) => {
      const { estimatedUploadFinishEpoch, numBytesDone, vid } = action.payload
      const found = findVidInArray(state.uploadsInProgress, vid)
      assert(found, 'upload not found in the progress list', { vid, uploadsInProgress: state.uploadsInProgress })
      found.estimatedUploadFinishEpoch = estimatedUploadFinishEpoch // may be undefined if we don't know
      found.numBytesDone = numBytesDone
      // no need to save state to local storage; only updated ephemeral state
    },
    uploadEnded: (state, action) => {
      const { error, vid, fid } = action.payload
      // remove from in progress list
      let found
      const foundIdx = findVidInArray(state.uploadsInProgress, vid)
      if (foundIdx === undefined) {
        found = action.payload
      } else {
        found = state.uploadsInProgress.splice(foundIdx, 1)[0]
      }

      // add to the upload ended list
      const uploadEndedInfo = {
        error,
        ...getUploadInfo(found)
      }
      state.endedUploads.push(uploadEndedInfo)
      if (!error) {
        state.recentlyCompletedUploadVIDs.splice(0, 0, vid)
        if (fid) {
          state.recentlyUploadedVIDsFolders.splice(0, 0, { vid, fid })
        }
      }
      saveState(state)
      const extraAnalyticsData = {
        success: !error
      }
      if (error) {
        extraAnalyticsData.error = error.substring(0, 255)
      }
      trackUploadAnalyticsEvent('ended', found, extraAnalyticsData)
    },
    uploadFromNativeAppDone: (state, action) => {
      // payload is contained of a fid and vid. Fid can be omitted
      const payload = action.payload

      if (state.recentlyCompletedUploadVIDs.indexOf(payload.vid) === -1) {
        state.recentlyCompletedUploadVIDs.splice(0, 0, payload.vid)
        if (payload.fid) {
          state.recentlyUploadedVIDsFolders.splice(0, 0, payload)
        }
      }
    },
    uploadCanceled: (state, action) => {
      const { vid } = action.payload
      const foundIdx = findVidInArray(state.uploadsInProgress, vid)
      assert(foundIdx !== undefined, `vid=${vid} not found`)
      state.uploadsInProgress.splice(foundIdx, 1)
      delete state.anonymousUploadsNotYetAuthoritativelyClaimed[vid]
      saveState(state)
    },
    uploadFormatErrored: (state, action) => {
      state.uploadFormatError = Boolean(action.payload)
      saveState(state)
    },
    uploadRetryRequested: (state, action) => {
      const { vid } = action.payload
      // remove it from ended uploads
      const foundIdx = findVidInArray(state.endedUploads, vid, true)
      assert(foundIdx !== undefined, 'did not find ended video to retry')
      const endedUpload = state.endedUploads.splice(foundIdx, 1)[0]
      assert(endedUpload.error, 'cannot retry a successful upload')
      // add it back to in progress uploads
      const uploadInProgress = {
        ...getUploadInfo(endedUpload),
        startEpoch: undefined,
        numBytesDone: 0
      }
      state.uploadsInProgress.push(uploadInProgress)
      saveState(state)
    },
    claimAttemptStatusChange: (state, action) => {
      const { isClaiming, vid } = action.payload
      const cur = state.anonymousUploadsNotYetAuthoritativelyClaimed[vid]
      if (cur) {
        cur.isClaiming = isClaiming
      }
      saveState(state)
    },
    claimed: (state, action) => {
      const { isAuthClaim, vid } = action.payload
      const pending = state.anonymousUploadsNotYetAuthoritativelyClaimed[vid]
      if (!pending) {
        // this case is unlikely, but can happen if prelim and auth claim are
        // done in quick succession; this case just means auth finished first
        return
      }
      if (isAuthClaim) {
        delete pending[vid]
      } else {
        pending[vid].isPreliminarilyClaimed = true
      }
      saveState(state)
    },
    clearEndedUpload: (state, action) => {
      const { vid } = action.payload
      const idx = findVidInArray(state.endedUploads, vid, true)
      assert(idx !== undefined, 'cannot clear missing ended upload')
      state.endedUploads.splice(idx, 1)
      saveState(state)
    },
    clearAllEndedUploads: state => {
      state.endedUploads = []
      saveState(state)
    },
    removeRecentlyCompletedUpload: (state, action) => {
      const { vid } = action.payload
      // it's okay if we call this for a vid that we didn't recently upload
      // (caller doesn't need to check, can just fire it as a just-in-case)
      const idx1 = state.recentlyCompletedUploadVIDs.indexOf(vid)
      if (idx1 !== undefined) {
        state.recentlyCompletedUploadVIDs.splice(idx1, 1)
      }
      const idx2 = findVidInArray(state.endedUploads, vid, true)
      if (idx2 !== undefined) {
        state.endedUploads.splice(idx2, 1)
      }
    },
    sawVidsInLibrary: (state, action) => {
      const { vids } = action.payload
      const vidsSet = new Set(vids)
      // now that we've seen them in the library, we don't need to remember
      // them here too
      state.recentlyCompletedUploadVIDs = state.recentlyCompletedUploadVIDs.filter(
        vid => !vidsSet.has(vid))
      saveState(state)
    },
    claimedAnonymouslyUploadedVideo: (state, action) => {
      if (!state.claimResult) {
        state.claimResult = action.payload
      }
      // nothing to save to local storage, this is ephemeral state only
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userChanged, state => {
      // when the user signs in or out, only keep completed VIDs that are also
      // unclaimed (the new user can claim them)
      const vidsPendingClaim = new Set(Object.keys(state.anonymousUploadsNotYetAuthoritativelyClaimed))
      state.recentlyCompletedUploadVIDs = state.recentlyCompletedUploadVIDs.filter(
        recentVID => vidsPendingClaim.has(recentVID))
      state.endedUploads = state.endedUploads.filter(
        endedUpload => vidsPendingClaim.has(endedUpload.vid))
      saveState(state)
    })
  }
})

export const { reducer } = uploadsSlice
export const { removeRecentlyCompletedUpload, uploadFormatErrored, uploadFromNativeAppDone } = uploadsSlice.actions

/**
 * @typedef {object} UploadInfo
 * @property {string} vid unique video id
 * @property {string} uploaderUserId may be an anonymous ID
 * @property {int} numBytesTotal size of the video being uploaded
 * @property {string} thumbnail the thumbnail image data (JPG data URL)
 * @property {int} width horizontal size in pixels
 * @property {int} height vertical size in pixels
 * @property {int} duration length in seconds
 * @property {string} name video's name
 */
/**
 * Information about the Video that can be set prior to it being uploaded.
 *
 * @typedef {object} UploadOptions
 * @property {string} [name] the title of the game (if omitted, we'll use the
 *   time of the game, or if that isn't provided then the time of the upload)
 * @property {string} [desc] a longer description of the game
 * @property {number} [gameStartEpoch] the epoch at which the game started
 */
/**
 * Call the function returned by this whenever an upload starts.
 * @returns {function(File, UploadOptions): void}
 */
export function useQueueNewUpload () {
  const uploaderUserId = useUserId()
  const dispatch = useDispatch()
  const queueNewUploadWrapper = useCallback((file, options = {}) => {
    dispatch(queueNewUpload(uploaderUserId, file, options))
  }, [dispatch, uploaderUserId])
  return queueNewUploadWrapper
}

/**
 * Function that checks upload video restrictions
 * Returns first found error
 *
 * @param {File} file
 * @param {number} width
 * @param {number} height
 * @param {number} duration
 * @returns {string}
 */
function getVideoRestrictionsError (file, width, height, duration) {
  const MAX_DURATION = 1800
  const MIN_WIDTH = 1280
  const MIN_HEIGHT = 720
  const MAX_WIDTH = 3840
  const MAX_HEIGHT = 2160
  const MAX_SIZE = 10737418240

  const { size } = file

  if (duration > MAX_DURATION) {
    return 'This video is too long (max 30 minutes)'
  }
  if (width < MIN_WIDTH && height < MIN_HEIGHT) {
    return 'This video’s resolution is too low (min 720p)'
  }
  if (width > MAX_WIDTH && height > MAX_HEIGHT) {
    return 'This video’s resolution is too high (max 4K)'
  }
  if (size > MAX_SIZE) {
    return 'This video’s file size is too large (max 10GB)'
  }
}

function queueNewUpload (uploaderUserId, file, options) {
  return async (dispatch) => {
    const { fps, width, height, duration, thumbnail, error } = await getVideoMeta(file).catch((error) => ({ error }))
    if (error) {
      dispatch(uploadsSlice.actions.uploadFormatErrored(true))
    } else {
      // use file name as name if options doesn't provide a custom name
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.')

      const name = options.name ?? fileNameWithoutExtension
      const { vid, fid } = await makeUploadId(
        uploaderUserId,
        { fps, secs: duration, width, height },
        file,
        { name, ...options })
      inMemoryState.files[vid] = file

      const newUpload = {
        vid,
        fid,
        uploaderUserId,
        numBytesTotal: file.size,
        thumbnail,
        width,
        height,
        duration,
        name,
        numBytesDone: 0,
        startEpoch: undefined
      }
      const error = getVideoRestrictionsError(file, width, height, duration)
      if (error) {
        dispatch(uploadsSlice.actions.uploadEnded({ ...newUpload, error }))
      } else {
        dispatch(uploadsSlice.actions.uploadQueued(newUpload))
        trackUploadAnalyticsEvent('queued', newUpload)
      }
    }
  }
}
async function makeUploadId (uploaderUserId, metadata, file, options) {
  const { desc, name, gameStartEpoch, fid = null } = options
  const body = {
    fileExt: getFileExt(file),
    name,
    videoMetadata: metadata
  }
  if (fid) {
    body.fid = fid
  }

  // if given extra information about this video, include that too
  if (desc || gameStartEpoch) {
    Object.assign(body, pick(options, ['desc', 'gameStartEpoch']))
  }

  const isAnonymous = isAnonymousUserId(uploaderUserId)
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-uid': uploaderUserId
  }
  if (!isAnonymous) {
    headers['x-token'] = await getLoggedInUserToken()
  }
  const url = `${import.meta.env.VITE_API_SERVER}/video/make_upload_id`
  const resp = await window.fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })
  const respBody = await resp.text()
  if (!resp.ok) {
    throw new Error(`failed to get upload id: ${respBody}`)
  }
  const { vid } = JSON.parse(respBody)
  return { vid, fid }
}

/**
 * Logs an analytics event about a video upload.
 * @param {string} eventNameSuffix used as part of the event name
 * @param {UploadInfo} upload information about the upload
 * @param {object} [extraAnalyticsData] extra key-value pairs to include
 */
function trackUploadAnalyticsEvent (eventNameSuffix, upload, extraAnalyticsData = {}) {
  const { duration, width, height, numBytesTotal, vid } = upload
  const fps = null // TODO: we aren't currently computing this
  mixpanel.track(`video_upload_${eventNameSuffix}`, {
    ...extraAnalyticsData,
    secs: duration,
    width,
    height,
    bytes: numBytesTotal,
    vid,
    fps
  })
}

/**
 * Call the function returned when retrying an upload.
 * @returns {function(vid): void}
 */
export function useNotifyUploadShouldRetry () {
  const dispatch = useDispatch()
  return useCallback(vid => {
    assert(inMemoryState.files[vid], 'missing file to retry upload')
    dispatch(uploadsSlice.actions.uploadRetryRequested({ vid }))
  }, [dispatch])
}

export const { clearEndedUpload, clearAllEndedUploads } = uploadsSlice.actions

// if we saw a vid in our (logged in user) library, then we don't need to track
// them in the recently completed upload vids list anymore
export function sawVidsInLibrary (vids) {
  return (dispatch, getState) => {
    const vidsInState = new Set(getState()[sliceName].recentlyCompletedUploadVIDs)
    const vidsToRemoveFromState = vids.filter(vid => vidsInState.has(vid))
    if (vidsToRemoveFromState.length) {
      dispatch(uploadsSlice.actions.sawVidsInLibrary({ vids: vidsToRemoveFromState }))
    }
  }
}

/** Preliminarily claim any unclaimed videos with the unverified email provided by the user */
export function preliminarilyClaimAnonymouslyUploadedVideos (email) {
  return async (dispatch, getState) => {
    const pendingMap = getState()[sliceName].anonymousUploadsNotYetAuthoritativelyClaimed
    const promises = []
    for (const vid of Object.keys(pendingMap)) {
      assert(email, 'neither uid nor email provided')
      const { isPreliminarilyClaimed } = pendingMap[vid]
      if (!isPreliminarilyClaimed) {
        promises.push(window.fetch(`${import.meta.env.VITE_API_SERVER}/video/claim_anonymous_upload/preliminary`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email, vid })
        }).then(resp => {
          if (resp.ok) {
            dispatch(uploadsSlice.actions.claimed({ vid, isAuthClaim: false }))
          } else {
            console.error('preliminary claim failed', resp.status)
          }
        }, err => console.error('preliminary claim failed', err)))
        if (promises.length === 1) {
          // make sure the first promise finishes first since that's the only
          // result we are going to look at
          await promises[0]
        }
      }
    }
    const responses = await Promise.all(promises)
    const { isFirstVideo, isPasswordSet } = await responses[0].json()
    dispatch(uploadsSlice.actions.claimedAnonymouslyUploadedVideo({
      preliminary: true,
      isFirstVideo,
      isPasswordSet
    }))
  }
}

export function useAnonymouslyUploadedVideoClaimResponse () {
  return useSliceState(s => s.claimResult)
}

/** Authoritatively claim any unclaimed videos with the verified user id */
function authoritativelyClaimAnonymouslyUploadedVideos (uid) {
  return async (dispatch, getState) => {
    const pendingMap = getState()[sliceName].anonymousUploadsNotYetAuthoritativelyClaimed
    const promises = []
    let isFirstVideo
    for (const vid of Object.keys(pendingMap)) {
      const pending = pendingMap[vid]
      if (pending.isClaiming) {
        continue
      }
      dispatch(uploadsSlice.actions.claimAttemptStatusChange({ isClaiming: true, vid }))

      const promise = authoritativelyClaimAnonymouslyUploadedVideo(dispatch, uid, vid)
      if (isFirstVideo === undefined) {
        // make sure the first promise finishes first since that's the only
        // result we are going to look at
        const resp = await promise
        if (resp) {
          isFirstVideo = resp.json().isFirstVideo
        }
      } else {
        promises.push(promise)
      }
    }
    if (promises.length) {
      await Promise.all(promises)
    }
    if (isFirstVideo !== undefined) {
      dispatch(uploadsSlice.actions.claimedAnonymouslyUploadedVideo({ isFirstVideo }))
    }
  }
}

async function authoritativelyClaimAnonymouslyUploadedVideo (dispatch, uid, vid) {
  let resp
  try {
    resp = await window.fetch(`${import.meta.env.VITE_API_SERVER}/video/claim_anonymous_upload/authenticated`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-uid': uid,
        'x-token': await getLoggedInUserToken()
      },
      body: JSON.stringify({ vid })
    })
  } catch (err) {
    console.error('auth claim failed', err)
    return
  }

  if (resp.ok) {
    dispatch(uploadsSlice.actions.claimed({ vid, isAuthClaim: true }))
    return resp
  } else {
  // if it was already claimed (error code 400), then treat it as claimed
    console.error('auth claim failed', resp.status)
    dispatch(uploadsSlice.actions.claimAttemptStatusChange({
      isClaiming: resp.status === 400,
      vid
    }))
  }
}

/**
 * Extracts the UploadInfo fields from an object.
 * @param {Object} uploadInfoInstance an object which extends UploadInfo
 * @returns {UploadInfo} a copy of just the UploadInfo fields
 */
function getUploadInfo (uploadInfoInstance) {
  const { vid, uploaderUserId, thumbnail, numBytesTotal, width, height, duration, name } = uploadInfoInstance
  return { vid, uploaderUserId, thumbnail, numBytesTotal, width, height, duration, name }
}

function findVidInArray (array, vid, findIndex = false) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].vid === vid) {
      return findIndex ? i : array[i]
    }
  }
}

/** Returns vids which finished uploading this session from newest to oldest. */
export function useRecentlyCompletedUploadVIDs () {
  const vidsFromNative = useSelector(x => x.anonymous.vidsFromNative)
  const vidsFromWebview = useSliceState(x => x.recentlyCompletedUploadVIDs)
  const vidsFolder = useSliceState(x => x.recentlyUploadedVIDsFolders)
  return useMemo(() => {
    const ret = {
      videos: Array.from(new Set([...vidsFromNative, ...vidsFromWebview])),
      folders: vidsFolder
    }
    return ret
  }, [vidsFolder, vidsFromNative, vidsFromWebview])
}

export function useUploads () {
  const ended = useSliceState(x => x.endedUploads)
  const inProgress = useSliceState(x => x.uploadsInProgress)
  return useMemo(() => {
    const uploads = [
      ...inProgress,
      ...ended
    ]
    return uploads.map((upload, i) => {
      const isEnded = i >= inProgress.length
      const status = getUploadStatus(upload, isEnded)
      return { ...upload, status }
    })
  }, [ended, inProgress])
}
function getUploadStatus (upload, isEnded) {
  if (upload.error) {
    return 'failed' // the upload failed (does not include canceled uploads)
  }
  if (isEnded) {
    return 'succeeded' // as in the upload is done
  }
  if (upload.startEpoch !== undefined) {
    return 'uploading'
  }
  return 'pending'
}

/**
 * Starts the next upload (if any) as soon as the previous one finishes.
 */
export function useUploadsManager () {
  const dispatch = useDispatch()
  const inProgress = useSliceState(x => x.uploadsInProgress)
  const next = inProgress[0]
  const isLoggedIn = useIsLoggedIn()
  const pendingAuthClaims = useSliceState(x => x.anonymousUploadsNotYetAuthoritativelyClaimed)
  const userId = useUserId()

  // claim authoritatively when possible
  useEffect(() => {
    if (isLoggedIn && Object.keys(pendingAuthClaims).length) {
      dispatch(authoritativelyClaimAnonymouslyUploadedVideos(userId))
    }
  }, [dispatch, isLoggedIn, pendingAuthClaims, userId])

  // upload when possible
  useEffect(() => {
    if (next && next.startEpoch === undefined) {
      const { uploaderUserId, vid, fid } = next
      dispatch(startUpload(uploaderUserId, vid, fid))
    }
    // otherwise next is already started or there is no next
  }, [dispatch, next])
}
function startUpload (uploaderUserId, vid, fid) {
  return async (dispatch, getState) => {
    const file = inMemoryState.files[vid]
    assert(file, 'missing file to upload', { inMemoryState, vid })
    const bucket = import.meta.env.VITE_UPLOADS_BUCKET
    const objName = `${uploaderUserId}/${vid}.${getFileExt(file)}`
    inMemoryState.abortController = new AbortController()
    const abortSignal = inMemoryState.abortController.signal
    const startMillis = millinow()
    const onProgress = ({ progress: percentDone, ...rest }) => {
      const numBytesDone = Math.floor(percentDone * file.size)
      const curMillis = millinow()
      const millisElapsed = curMillis - startMillis
      let estimatedUploadFinishEpoch
      if (millisElapsed > 0 && numBytesDone > 0) {
        const bytesPerSec = numBytesDone / millisElapsed * 1000
        const bytesLeft = file.size - numBytesDone
        const secsLeft = bytesLeft / (bytesPerSec || 1e6)
        estimatedUploadFinishEpoch = Math.ceil(curMillis / 1000 + secsLeft)
      }
      dispatch(uploadsSlice.actions.uploadProgressed({
        vid,
        numBytesDone,
        estimatedUploadFinishEpoch
      }))
    }
    const startEpoch = now()
    dispatch(uploadsSlice.actions.uploadStarted({ startEpoch, vid }))
    try {
      /*
       * From MDN: It is therefore recommended that developers listen for beforeunload only
       * when users have unsaved changes so that the dialog mentioned above can be used
       * to warn them about impending data loss, and remove the listener again when it is not needed.
       * Listening for beforeunload sparingly can minimize the effect on performance.
       */
      window.addEventListener('beforeunload', preventLeave)
      await uploadToGCS(bucket, objName, file, abortSignal, onProgress)
      window.removeEventListener('beforeunload', preventLeave)
    } catch (err) {
      console.error('upload failed', err)
      window.removeEventListener('beforeunload', preventLeave)
      return
    }

    delete inMemoryState.files[vid]
    dispatch(uploadsSlice.actions.uploadEnded({ vid, fid, error: null }))
    dispatch(watchVideoProgress(vid))
    if (isAnonymousUserId(uploaderUserId)) {
      dispatch(newUnseenAnonymousVideo({ vid }))
    }
    // Library should refresh in a few seconds (not right away because it
    // takes several seconds for the workflow to start and then add the
    // video to be added to the library)
    await sleep(10000) // might be able to go lower, like 5sec but no rush (video will show up in library because it is in the recently completed list too)
    dispatch(apisSlice.util.invalidateTags([
      { type: 'VideoExcerpt', id: 'Library' }
    ]))
  }
}

async function uploadToGCS (bucket, objName, file, abortSignal, onUploadProgress) {
  // request to start a new upload
  const url = `https://storage.googleapis.com/upload/storage/v1/b/${bucket}/o?uploadType=resumable&name=${objName}`
  const numBytesTotal = file.size
  const headers = { 'X-Upload-Content-Length': numBytesTotal }
  const resp = await fetch(url, { method: 'POST', headers })
  if (!resp.ok) {
    throw new Error(`PB Vision Upload failed to initialize (${resp.status}): ${await resp.text()}`)
  }

  const sessionURI = resp.headers.get('Location')
  const config = {
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    onUploadProgress,
    signal: abortSignal
  }
  return axios.put(sessionURI, file, config)
}
function preventLeave (event) {
  event.preventDefault()

  // Included for legacy support, e.g. Chrome/Edge < 119
  // eslint-disable-next-line no-param-reassign
  event.returnValue = true
}

export function useCancelUpload () {
  const dispatch = useDispatch()
  const inProgress = useSliceState(x => x.uploadsInProgress)
  return useCallback(vid => {
    const uploadToCancel = findVidInArray(inProgress, vid)
    if (!uploadToCancel) {
      return // must've finished before this could run
    }
    // tell the upload to abort if it is in progress
    if (inProgress[0] === uploadToCancel && inProgress[0].startEpoch) {
      inMemoryState.abortController.abort()
    }
    dispatch(uploadsSlice.actions.uploadCanceled({ vid }))
  }, [dispatch, inProgress])
}

// anonymous users can only upload one video
export function useCanUploadMoreVideos () {
  const isLoggedIn = useIsLoggedIn()
  const hasUploadedAnything = useSliceState(
    x => Boolean(x.endedUploads.length || x.uploadsInProgress.length))
  return isLoggedIn || !hasUploadedAnything
}

export function useRecentlyEndedUpload (vid) {
  const ended = useSliceState(x => x.endedUploads)
  return findVidInArray(ended, vid)
}

export function useHasAnonymouslyUploadedVideoPending () {
  const pendingAuthClaims = useSliceState(x => x.anonymousUploadsNotYetAuthoritativelyClaimed)
  return Object.keys(pendingAuthClaims).length > 0
}

export function useUploadFormatErrored () {
  return useSelector(state => state[sliceName].uploadFormatError)
}
