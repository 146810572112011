import CloseIcon from '@mui/icons-material/Close'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Container } from './container'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import { copyToClipboard } from '@/utils/helper'
import {
  isInMobileAppWebview,
  isVersionedFeatureAllowed,
  notifyShareRequested
} from '@/utils/mobile-app-communication'

/**
 * AddNewUserPopup component
 *
 * @exports
 * @param props {object} { open, anchorEl, onClose, playerName, onAddUserEmail, onAddUserPhone }
 * @returns {React.ReactElement}
 */
let shareURL = ''
export const AddNewUserPopup = (props) => {
  const isMobile = useMobileDetect()
  const { open, anchorEl, playerName, vid, playerIdx, aiEngineVersion } = props
  const { nestParamsIntoURL } = useURLParams()
  const openSnackbar = useSnackbar()
  const [searchParams] = useSearchParams()

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  useEffect(() => {
    shareURL = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`
  }, [searchParams, vid])

  const onClose = () => props.onClose()

  const onMouseDown = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const onTextMessageClick = () => {
    props.onAddUserPhone()
  }

  const onEmailClick = () => {
    props.onAddUserEmail()
  }

  const onSendLinkClick = () => {
    if (isInMobileAppWebview()) {
      isVersionedFeatureAllowed('2.0.1') && notifyShareRequested(nestParamsIntoURL(shareURL))
    } else {
      copyLinkToClipboard()
    }
  }

  const copyLinkToClipboard = async () => {
    const url = nestParamsIntoURL(shareURL)
    copyToClipboard(url)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  return (
    <Container
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      placement='bottom-start'
      onMouseDown={onMouseDown}
      className={clsx([{ mobile: isMobile }])}
    >
      <div className='header'>
        <div className='title-section'>
          <div className='avatar'>P</div>
          <span className='title'>{playerName}</span>
        </div>
        <CloseIcon className='close-icon' onClick={onClose} />
      </div>
      <div className='content'>
        <div className='player-images'>
          {playerImages.map((a, i) => (
            <img key={i} src={normalizeUrl(a)} alt='' />
          ))}
        </div>
        <div className='button-section'>
          <Button startIcon={<SmsOutlinedIcon />} onClick={onTextMessageClick} variant='outlined'>
            Text Message
          </Button>
          <Button startIcon={<MailOutlinedIcon />} onClick={onEmailClick} variant='outlined'>
            Email
          </Button>
          <Button startIcon={<LinkOutlinedIcon />} onClick={onSendLinkClick} variant='outlined'>
            Send a link
          </Button>
        </div>
      </div>
    </Container>
  )
}
