import DownloadIcon from '@mui/icons-material/Download'
import InfoIcon from '@mui/icons-material/Info'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/Share'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import { Menu, MenuItem, ListItemIcon, InputBase, SvgIcon } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams, Link, useMatch } from 'react-router-dom'

import DownloadRawDataModal from '../raw-data-dialog'

import ExcelIcon from '@/assets/icons/excel-icon.svg?react'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { diffFilters } from '@/pages/shot-explorer/helpers'
import { showCreateAccountBanner } from '@/store/anonymous'
// import { setVideoNameInLibraryOnly } from '@/store/library'
import { useIsLoggedIn } from '@/store/auth'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { useShotFilters } from '@/store/shot-filter'
import { setVideoField, useGetVideo, useMayEditVideo } from '@/store/video'
import { isProd } from '@/utils'
import cl from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { downloadOrOpenLink } from '@/utils/download'
import { row } from '@/utils/flexGrid'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview, isVersionedFeatureAllowed, notifyShareRequested } from '@/utils/mobile-app-communication'
import { getVideoURLForDownload } from '@/utils/video'

/**
 * TitleBar container
 *
 * @returns {React.ReactElement}
 */
const TitleToolbar = styled('div')({
  // alignItems: 'flex-end',
  color: COLORS['neutral-700'],
  // display: 'flex',
  fontSize: 24,
  fontWeight: 600,
  marginBottom: 8,

  // Child elements
  '& .row': {
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    overflow: 'hidden',
    ...row
  },
  '& .title': {
    marginRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '& .date': {
    display: 'flex',
    padding: '5px 0',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500
  },
  '& #share-button': {
    minWidth: 88
  },
  '& form': {
    display: 'inline-flex',
    '& input': {
      fontSize: 24,
      fontWeight: 600,
      background: COLORS['neutral-050'],
      border: `2px solid ${COLORS['neutral-400']}`,
      borderRadius: 4
    }
  },
  '& .btn-grp': {
    minWidth: 300,
    backgroundColor: COLORS.white,
    paddingBlock: '8px',
    border: `1px solid ${COLORS['neutral-300']}`,
    color: COLORS['neutral-700'],
    '& svg': {
      color: 'inherit',
      marginRight: 4
    },
    '&:hover': {
      borderColor: COLORS['neutral-400']
    },
    '&.insights': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    '&.explorer': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    '&.selected': {
      borderColor: `1px solid ${COLORS['neutral-500']}`,
      color: COLORS.white,
      backgroundColor: COLORS['primary-500']
    }
  },

  // Mobile specific definitions
  '&.mobile': {
    // marginBottom: 0,
    margin: '0 8px',
    '& .title': {
      fontSize: 18
    },
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      minHeight: 'unset'
    }
  }
})

// Workaround for eslint issue with multiline-ternary / react/jsx-closing-tag-location and react/jsx-curly-newline
const IfElse = (props) => {
  return props.condition ? props.children : props.fallback
}

export function useMenu () {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  const openMenu = (event) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return { anchorEl, isOpen, openMenu, closeMenu }
}

/**
 * Top Insights title bar, showing date / game name and share / download menus
 *
 * @exports
 * @param props {object} {date, name}
 * @returns {React.ReactElement}
 */

let shareURL = ''
export function TitleBar (props) {
  const { date, name, video, isWorkflowDone } = props
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const openSnackbar = useSnackbar()
  const { nestParamsIntoURL } = useURLParams()
  const isMobile = useMobileDetect()
  const isWebView = isInMobileAppWebview()
  const params = useParams()
  const actionMenu = useMenu()
  const isExplorer = useMatch('/video/:vid/explore')
  const { vid } = params
  const filters = useShotFilters()
  const mayEdit = useMayEditVideo(vid)
  const isLoggedIn = useIsLoggedIn()
  const [isEditingVideoName, setIsEditingVideoNameInState] = useState(false)
  const [editNameValue, setEditNameValue] = useState(name)
  const [showRawDataDialog, setShowRawDataDialog] = useState(false)
  const videoDetails = useGetVideo(vid)

  useEffect(() => {
    shareURL = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    shareURL += `/video/share/${vid}`
  }, [isExplorer, searchParams, vid])

  const setIsEditingVideoName = useCallback(newValue => {
    if (newValue && !isLoggedIn) {
      // prompt user to login so they can edit the name
      dispatch(showCreateAccountBanner(true))
    } else {
      setIsEditingVideoNameInState(newValue)
    }
  }, [dispatch, isLoggedIn])

  const shareButtonHandler = (event) => {
    if (isInMobileAppWebview()) {
      isVersionedFeatureAllowed('2.0.1') && notifyShareRequested(nestParamsIntoURL(shareURL))
    } else {
      copyLinkToClipboard()
    }
  }

  const onDownloadVideoHandler = () => {
    const url = getVideoURLForDownload(video)
    downloadOrOpenLink(url, '', true)
  }

  const copyLinkToClipboard = async () => {
    const url = isExplorer ? `${shareURL}?state=${encodeURI(JSON.stringify({ path: 'explore', ...diffFilters(filters) }))}` : nestParamsIntoURL(shareURL)
    copyToClipboard(url)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const updateVideoName = useCallback(async (newName) => {
    if (newName !== name) {
      // assuming the video is owned by us; it's possible we've added other people's videos to our library
      if (mayEdit) {
        dispatch(setVideoField(vid, 'name', newName))
      } else {
        // should we do this here ?
        // dispatch(setVideoNameInLibraryOnly(vid, newName))
      }
    }
  }, [name, mayEdit, dispatch, vid])

  const onChangeVideoName = (event) => {
    const newValue = event.target.value
    setEditNameValue(newValue)
  }

  const onNameClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsEditingVideoName(true)
  }

  const openRawDataDialog = () => {
    setShowRawDataDialog(true)
    actionMenu.closeMenu()
  }

  const openInfoLink = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const link = 'https://pb.vision/post/interpreting-pb-visions-advanced-stats'
    downloadOrOpenLink(link, null, true)
  }

  const downloadExcelStats = () => {
    const aiEngineVersion = videoDetails.data.workflows[0].aiEngineVersion
    const file = `${aiEngineVersion}/${vid}_${aiEngineVersion}_stats.xlsx`
    const url = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${file}`
    downloadOrOpenLink(url, file, true)
  }

  return (
    <TitleToolbar className={cl(isMobile && 'mobile')}>
      <div className='row'>
        <IfElse condition={isEditingVideoName} fallback={<span className='title' onClick={onNameClick}>{name}</span>}>
          <form onSubmit={() => updateVideoName(editNameValue)}>
            <InputBase
              autoFocus
              className='title'
              value={editNameValue}
              onChange={onChangeVideoName}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onKeyDown={async (key) => {
                if (key.code === 'Enter') {
                  setIsEditingVideoName(false)
                  await updateVideoName(editNameValue)
                } else if (key.code === 'Escape') {
                  setIsEditingVideoName(false)
                }
              }}
              onBlur={() => {
                updateVideoName(editNameValue)
                setIsEditingVideoName(false)
              }}
            />
          </form>
        </IfElse>
        <span className='date'>Game Date: {date}</span>
      </div>
      <div className='row'>
        <Button component={Link} className={cl(!isExplorer && 'selected', 'btn-grp', 'insights')} to={`/video/${vid}`}>
          <LightbulbOutlinedIcon />Game Insights
        </Button>
        <Button component={Link} className={cl(isExplorer && 'selected', 'btn-grp', 'explorer')} to={`/video/${vid}/explore`}>
          <VideocamOutlinedIcon />Shot Explorer
        </Button>
        <Button
          variant='outlined'
          color='midnight'
          className='neutral-outline'
          sx={{ alignSelf: 'center', marginLeft: 'auto' }}
          id='share-button'
          onClick={shareButtonHandler}
        >
          <em>Share</em>
          <ShareIcon />
        </Button>
        {!isWebView && (
          <>
            <Button
              variant='outlined'
              color='midnight'
              className='neutral-outline'
              sx={{
                alignSelf: 'center',
                marginLeft: '9px',
                minWidth: 'auto',
                padding: '2px 6px',
                '& svg': { marginLeft: 0, color: COLORS['neutral-500'] }
              }}
              id='action-button'
              aria-controls={actionMenu.isOpen ? 'action-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={actionMenu.isOpen ? 'true' : undefined}
              onClick={(e) => actionMenu.openMenu(e)}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              id='action-menu'
              anchorEl={actionMenu.anchorEl}
              open={actionMenu.isOpen}
              onClose={() => actionMenu.closeMenu()}
              MenuListProps={{ 'aria-labelledby': 'action-button' }}
            >
              <MenuItem onClick={onDownloadVideoHandler}>
                <ListItemIcon>
                  <DownloadIcon fontSize='small' />
                </ListItemIcon>
                Download Video
              </MenuItem>
              <MenuItem onClick={downloadExcelStats} disabled={!isWorkflowDone}>
                <ListItemIcon>
                  <SvgIcon fontSize='18' component={ExcelIcon} />
                </ListItemIcon>
                Download Stats (Excel)
                <Button
                  className='info-link-btn'
                  onClick={openInfoLink}
                  sx={{
                    margin: 0,
                    padding: 0,
                    minHeight: 'unset',
                    minWidth: 'unset',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <InfoIcon />
                </Button>
              </MenuItem>
              <MenuItem onClick={openRawDataDialog} disabled={!isWorkflowDone}>
                <ListItemIcon>
                  <TextSnippetIcon fontSize='small' />
                </ListItemIcon>
                Download Raw Data
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
      {showRawDataDialog && <DownloadRawDataModal open={showRawDataDialog} onClose={() => setShowRawDataDialog(false)} vid={vid} />}
    </TitleToolbar>
  )
}
