const COLORS = {
  'primary-100': '#c1ffbd',
  'primary-300': '#37f32d',
  'primary-500': '#13d208',
  'primary-700': '#167610',

  'neutral-050': '#fbfcfe',
  'neutral-100': '#f0f4f8',
  'neutral-200': '#dde7ee',
  'neutral-300': '#cdd7e1',
  'neutral-400': '#9fa6ad',
  'neutral-500': '#636b74',
  'neutral-700': '#32383e',
  'neutral-800': '#171a1c',

  'danger-050': '#fff1f1',
  'danger-200': '#FFA5A5',
  'danger-500': '#ff2c2c',
  'danger-700': '#D20E0E',

  'warning-050': '#fff8df',
  'warning-200': '#FFE68F',
  'warning-500': '#e9b500',
  'warning-600': '#D4A705',
  'warning-800': '#745E12',
  'warning-900': '#3A300C',

  'text-tertiary': '#555E68',
  divider: 'rgba(99, 107, 116, 0.30)',

  'Quality_scale-Poor': '#FF2C2C',
  'Quality_scale-Fair': '#FF6816',
  'Quality_scale-Average': '#FFA500',
  'Quality_scale-Good': '#B4E100',
  'Quality_scale-Excellent': '#11EB05',

  'quality-gradient-horizontal':
    'linear-gradient(90deg, #11EB05 0%, #6EE821 10.93%, #98DE18 21.75%, #C3D50E 32.74%, #EACC05 43.73%, #FFB705 54.72%, #FF970E 65.54%, #FF7718 76.53%, #FF5421 87.52%, #FF2C2C 100%)',
  '00-on-surface-high-emphasis': 'rgba(0, 0, 0, 0.87)',
  'Players/Ball-machine': '#EF39FF',
  player1: '#0031ff',
  player2: '#00d1ff',
  player3: '#ffc700',
  player4: '#ff6006',

  black: '#000',
  white: '#fff',
  pink: '#EF39FF',

  'success-700': '#0DCD01',
  'success-800': '#109407',
  'success-300': '#54FE49',
  'success-050': '#DFFFDD',
  error: '#e44444',

  // 'joy-shadow-xs': '0px 1px 2px 0px 0px rgba(21,21,21,0.08)',
  'joy-shadow-xs': '0px 1px 2px 0px rgba(21, 21, 21, 0.08);',
  'background-tooltip': '#636B74'
}

export default COLORS
