import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { PageContext } from '../../context'
import { useBulkSelect } from '../../hooks/use-bulk-select'

import { Container } from './container'
import VideoContextMenu from './video-context-menu'

import TransparentCheckbox from '@/components/checkbox/transparent-checkbox'
import { CountdownRemaining } from '@/components/CountdownRemaining'
import OurDateTimePicker from '@/components/datetime-picker'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { showCreateAccountBanner } from '@/store/anonymous'
import { useIsLoggedIn } from '@/store/auth'
import { setVideoNameInLibraryOnly, useUnseenVIDs } from '@/store/library'
import { useAllLatestProcessingProgress } from '@/store/processing'
import { useRecentlyEndedUpload } from '@/store/uploads'
import { setVideoField } from '@/store/video'

function VideoThumb ({ videoExcerpt }) {
  const { secs, name, epoch, vid } = videoExcerpt

  const allProcessingProgress = useAllLatestProcessingProgress()
  const isMobile = useMobileDetect()
  const isTablet = useMobileDetect('tablet')
  const isDesktop = useMobileDetect('desktop')
  const [editNameValue, setEditNameValue] = useState(name)
  const [editDateValue, setEditDateValue] = useState(epoch * 1000)
  const [isEditingVideoName, setIsEditingVideoNameInState] = useState(false)
  const [isEditingVideoDate, setIsEditingVideoDateInState] = useState(false)
  const containerRef = useRef(null)
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const { bulkSelected, setBulkSelected, inlineVideoEditRef } = useContext(PageContext)
  const { toggleItemSelection, isItemSelected } = useBulkSelect(bulkSelected)

  const isSelected = isItemSelected({ vid })

  const setIsEditingVideoName = useCallback(newValue => {
    if (newValue && !isLoggedIn) {
      // prompt user to login so they can edit the name
      dispatch(showCreateAccountBanner(true))
    } else {
      setIsEditingVideoNameInState(newValue)
    }
  }, [dispatch, isLoggedIn])

  const setIsEditingVideoDate = useCallback(newValue => {
    if (newValue && !isLoggedIn) {
      // prompt user to login so they can edit the date
      dispatch(showCreateAccountBanner(true))
    } else {
      setIsEditingVideoDateInState(newValue)
    }
  }, [dispatch, isLoggedIn])

  const addItemToBulkSelected = () => {
    const selected = toggleItemSelection({ secs, name, epoch, vid })
    setBulkSelected(selected)
  }

  const unseenVIDsResult = useUnseenVIDs()

  useEffect(() => {
    const contextVideoEdit = inlineVideoEditRef?.current
    contextVideoEdit && Object.assign(contextVideoEdit, {
      vid,
      editNameValue,
      editDateValue,
      isEditingVideoName,
      isEditingVideoDate,
      setIsEditingVideoName,
      setIsEditingVideoDate,
      updateVideoDate
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNameValue, editDateValue, isEditingVideoName, isEditingVideoDate])

  const duration = (() => {
    if (typeof secs === 'undefined') return ''
    const minutes = Math.floor(secs / 60)
    const rem = secs - minutes * 60
    const minutesDisplay = String(minutes).padStart(2, '0')
    const secondsDisplay = String(rem).padStart(2, '0')
    return `${minutesDisplay}:${secondsDisplay}`
  })()

  const sourceURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${videoExcerpt.vid}/thumbnail.jpg`

  const onNameClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const contextVideoEdit = inlineVideoEditRef.current
    const {
      isEditingVideoName: contextIsEditingVideoName,
      isEditingVideoDate: contextIsEditingVideoDate,
      vid: contextVid
    } = contextVideoEdit

    if (contextIsEditingVideoDate) {
      contextVideoEdit.setIsEditingVideoDate?.(false)
      return
    }

    if (contextIsEditingVideoName && contextVid !== vid) {
      contextVideoEdit.setIsEditingVideoName(false)
    } else {
      setEditNameValue(name)
      setIsEditingVideoName(true)
    }
  }

  const onDateClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const contextVideoEdit = inlineVideoEditRef.current
    const {
      isEditingVideoName: contextIsEditingVideoName,
      isEditingVideoDate: contextIsEditingVideoDate,
      vid: contextVid
    } = contextVideoEdit

    if (contextIsEditingVideoName) {
      contextVideoEdit.setIsEditingVideoName?.(false)
      return
    }

    if (contextIsEditingVideoDate && contextVid !== vid) {
      contextVideoEdit.setIsEditingVideoDate(false)
    } else {
      setEditDateValue(epoch * 1000)
      setIsEditingVideoDate(true)
    }
  }

  const onChangeVideoName = (event) => {
    const newValue = event.target.value
    setEditNameValue(newValue)
  }

  const onChangeVideoDate = (value) => {
    if (value) {
      const newValue = value.toDate().valueOf()
      if (!isNaN(newValue)) {
        setEditDateValue(newValue)
        updateVideoDate(newValue)
        setIsEditingVideoDate(false)
      }
    }
  }

  const updateVideoName = useCallback(async (newName) => {
    // assuming the video is owned by us; it's possible we've added other
    // people's videos to our library
    if (videoExcerpt.notMine) {
      dispatch(setVideoNameInLibraryOnly(vid, newName))
    } else {
      dispatch(setVideoField(vid, 'name', newName))
    }
  }, [dispatch, vid, videoExcerpt.notMine])

  const updateVideoDate = useCallback(async (newTimestamp) => {
    if (isNaN(newTimestamp)) return

    if (!videoExcerpt.notMine) {
      const newEpoch = newTimestamp / 1000
      dispatch(setVideoField(vid, 'gameStartEpoch', newEpoch))
    }
  }, [dispatch, vid, videoExcerpt.notMine])

  // Apply changes to name or date when clicking away
  useEffect(() => {
    const listener = (event) => {
      if (isEditingVideoName && event.target.id !== 'inline-edit-video-name') {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        setIsEditingVideoName(false)
        updateVideoName(editNameValue)
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [
    editNameValue,
    isEditingVideoName,
    updateVideoName,
    editDateValue,
    isEditingVideoDate,
    updateVideoDate,
    containerRef,
    setIsEditingVideoName,
    setIsEditingVideoDate
  ])

  const renderNameSection = () => (
    <div className='name-section'>
      {isEditingVideoName && (
        // To catch the Android 'Go' button, we need parent form as it triggers the submit event
        <form onSubmit={() => updateVideoName(editNameValue)}>
          <TextField
            autoFocus
            id='inline-edit-video-name'
            value={editNameValue}
            onChange={onChangeVideoName}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onKeyDown={async (key) => {
              if (key.code === 'Enter') {
                setIsEditingVideoName(false)
                await updateVideoName(editNameValue)
              } else if (key.code === 'Escape') {
                setIsEditingVideoName(false)
              }
            }}
            onBlur={() => updateVideoName(editNameValue)}
          />
        </form>
      )}
      {!isEditingVideoName && (
        <div className='static-name-section' onClick={onNameClick}>
          <div className='name'>{name}</div>
          <ModeEditOutlineOutlinedIcon className='edit-icon' />
        </div>
      )}
    </div>
  )

  const renderDateSection = () => (
    <div className='date-section'>
      {isEditingVideoDate && (
      // To catch the Android 'Go' button, we need parent form as it triggers the submit event
        <form onSubmit={() => updateVideoDate(editDateValue)}>
          <OurDateTimePicker
            value={dayjs(editDateValue)}
            onAccept={(val) => onChangeVideoDate(val)}
            onCancel={() => setIsEditingVideoDate(false)}
          />
        </form>
      )}
      {!isEditingVideoDate && !!editDateValue && (
        <div className='static-date-section' onClick={onDateClick}>
          {/* https://day.js.org/docs/en/display/format */}
          <div className='date'>{dayjs(editDateValue).format('ddd, DD MMM')} &nbsp;&nbsp;&nbsp; {dayjs(editDateValue).format('hh:mm A')}</div>
        </div>
      )}
    </div>

  )

  const endedUpload = useRecentlyEndedUpload(vid)
  const thumbnailURL = endedUpload?.thumbnail
  const defaultImage = 'https://storage.googleapis.com/pbv-pro-dev/assets/open-graph-preview2.png'
  const urls = [sourceURL, thumbnailURL, defaultImage].filter(Boolean)

  const processingProgress = allProcessingProgress[videoExcerpt.vid]
  const isProcessing = processingProgress && processingProgress.percentDone < 1
  return (
    <Container
      ref={containerRef}
      className={clsx([{
        mobile: isMobile,
        tablet: isTablet,
        desktop: isDesktop
      }, isSelected && 'selected'])} to={`/video/${vid}`}
      data-vid={vid}
      onClick={(event) => {
        const contextVideoEdit = inlineVideoEditRef.current

        if (contextVideoEdit.isEditingVideoName) {
          contextVideoEdit.setIsEditingVideoName(false)
          event.preventDefault()
          event.stopPropagation()
        }
        if (isEditingVideoDate) {
          event.preventDefault()
          event.stopPropagation()
        }
      }}
    >
      <TransparentCheckbox onChange={addItemToBulkSelected} checked={isSelected} visible={Boolean(bulkSelected?.length)} />
      <div
        className='thumbnail-container'
        style={{
          backgroundImage: `${urls.map(url => `url('${url}')`).join(', ')}`
        }}
      >
        <div className='duration-container'>
          <div className='duration'>{duration}</div>
        </div>
        <div className={`playback-icon-overlay${isProcessing ? ' processing' : ''}`}>
          {isProcessing
            ? (
              <div>
                <p>AI Processing...</p>
                <p>about <CountdownRemaining epoch={processingProgress.estimatedFinishEpoch} /></p>
              </div>
              )
            : (
              <div className='playback-icon-container'>
                <PlayArrowIcon className='playback-icon' />
              </div>
              )}
        </div>
        {unseenVIDsResult.unseenVIDs.has(videoExcerpt.vid) && (
          <div className='red-dot-container'>
            <div className='red-dot' />
          </div>
        )}
      </div>
      <div className='footer'>
        <div className='footer-top'>
          {renderNameSection()}
          <VideoContextMenu className='menu-button' videoExcerpt={videoExcerpt} isProcessing={isProcessing} />
        </div>
        <div className='footer-bottom'>
          {renderDateSection()}
          {isProcessing && (
            <div className='completed'>
              {Math.floor(100 * processingProgress.percentDone)}% Complete
            </div>
          )}
        </div>
      </div>

    </Container>
  )
}

export default VideoThumb
