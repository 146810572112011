import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Button, FormControl, FormHelperText, Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddNewUserPopup } from '@/components/tag-player/add-new-user-popup'
import { AddUserEmailPopup } from '@/components/tag-player/add-user-email-popup'
import { AddUserPhonePopup } from '@/components/tag-player/add-user-phone-popup'
import { Container, AutocompletePaper, AutocompleteOption } from '@/components/tag-player/tag-player-popup/containers'
import { selectLoggedInUser } from '@/store/auth'
import { tagUser } from '@/store/library'

/**
 * TagPlayerPopup component
 *
 * @exports
 * @param props {object} {anchorEl, onClose, vid, playerIdx, initialName, playerName, aiEngineVersion}
 * @returns {React.ReactElement}
 */
export function TagPlayerPopup (props) {
  const dispatch = useDispatch()
  const { anchorEl, vid, playerIdx, initialName, playerName, aiEngineVersion } = props
  const [name, setName] = useState(initialName)
  const [error, setError] = useState('')
  const [displayError] = useState(false)
  const popperRef = useRef(null)
  const inputRef = useRef(null)
  const user = useSelector(selectLoggedInUser)

  const [recentlyTaggedListOpened, setRecentlyTaggedListOpened] = useState(false)
  const [addNewUserPopupOpened, setAddNewUserPopupOpened] = useState(false)
  const [addUserEmailPopupOpened, setAddUserEmailPopupOpened] = useState(false)
  const [addUserPhonePopupOpened, setAddUserPhonePopupOpened] = useState(false)

  const playerImages = []
  for (let imageIdx = 0; imageIdx < 4; imageIdx++) {
    playerImages.push(`https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/${aiEngineVersion}/player${playerIdx}-${imageIdx}.jpg`)
  }

  const doTagUser = (newName) => {
    if (!isNameValid(newName)) return

    if (newName !== playerName) {
      const value = newName.trim()
      dispatch(tagUser({ vid, playerIdx, name: value }))
    }
  }

  const onOpen = useCallback(() => {
    setName(initialName)
    setTimeout(() => {
      inputRef.current?.select()
    }, 0)
  }, [initialName])

  useEffect(() => {
    if (props.anchorEl) {
      onOpen()
    }
  }, [props.anchorEl, onOpen])

  const closeAllPopups = () => {
    setAddNewUserPopupOpened(false)
    setAddUserEmailPopupOpened(false)
    setAddUserPhonePopupOpened(false)
    props.onClose()
  }

  const onCloseTagPlayerPopup = (event, reason) => {
    if (reason !== 'escapeKeyDown') {
      doTagUser(name)
    }
    closeAllPopups()
  }

  // Tag player dialogs are Poppers opened at the same time, by design they don't close on outside click.
  // We need to close them manually from document click listener (which needs to sync with onCloseTagPlayerPopup).
  const closePopupFnRef = useRef(null)
  closePopupFnRef.current = onCloseTagPlayerPopup

  const documentClickRef = useRef((event) => {
    const targetEl = event.target
    if (document.body.contains(targetEl) && !hasParentWithClass(targetEl, 'base-Popper-root')) {
      closePopupFnRef.current?.()
    }
  })

  function hasParentWithClass (element, className) {
    let currentElement = element
    while (currentElement) {
      if (currentElement.classList && currentElement.classList.contains(className)) {
        return true
      }
      currentElement = currentElement.parentElement
    }
    return false
  }

  const normalizeUrl = (url) =>
    url.startsWith('http') || url.startsWith('data:image/') ? url : `data:image/png;base64,${url}`

  const onInputChange = (event, value) => setName(value)

  const isNameValid = (value) => {
    const trimmed = value?.trim()
    return trimmed?.length >= 2
  }

  useEffect(() => {
    setError(!isNameValid(name) ? 'Player Name must be at least 2 characters long' : '')
  }, [name])

  const options = user.recentlyTagged?.map((player, index) => ({ name: player.name, index })) || []

  const renderAutocompleteInput = (params) => (
    <FormControl error={!!error}>
      <TextField
        className='text-field'
        placeholder='Type a Name'
        autoFocus
        inputRef={inputRef}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password' // disable autocomplete and autofill
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon className='icon' />
            </InputAdornment>
          )
        }}
      />
      {displayError && !!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )

  const onAddNew = (event) => {
    setRecentlyTaggedListOpened(false)
    setAddNewUserPopupOpened(true)
  }

  const renderAutocompletePaper = (props) => (
    <AutocompletePaper {...props}>
      <div>{props.children}</div>
      <div
        className='footer' onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Button onClick={onAddNew} className='add-new' variant='outlined'>
          Add new
        </Button>
      </div>
    </AutocompletePaper>
  )

  const generatePlayerInitials = (name) => {
    const tokens = name.split(' ')
    return tokens.length === 1
      ? tokens[0].substring(0, 2).toUpperCase()
      : `${tokens[0][0].toUpperCase()}${tokens[1][0].toUpperCase()}`
  }

  const renderOptionAvatar = (option) => (
    <div className='avatar'>
      <span className='initials'>
        {generatePlayerInitials(option.name)}
      </span>
    </div>
  )

  const renderAutocompleteOption = (props, option) => {
    const { key, ...optionProps } = props
    return (
      <AutocompleteOption
        key={key}
        component='li'
        {...optionProps}
      >
        <div className='option-content'>
          {renderOptionAvatar(option)}
          <div className='name-section'>
            <span className='name'>
              {option.name}
            </span>
            <span className='info'>
              Recently tagged
            </span>
          </div>
        </div>
      </AutocompleteOption>
    )
  }

  const onOptionChange = (event, option) => {
    const newValue = option?.name || name
    setName(newValue)
    if (isNameValid(newValue)) {
      doTagUser(newValue)
      props.onClose()
    }
  }

  const renderContent = () => (
    <>
      <div className='header'>
        <span className='title'>Tag Player</span>
        <CloseIcon className='close-icon' onClick={onCloseTagPlayerPopup} />
      </div>
      <div className='content'>
        <div className='player-images'>
          {playerImages.map((a, i) => (
            <img key={i} src={normalizeUrl(a)} alt='' />
          ))}
        </div>
        <div className='avatar'>P</div>
        <Autocomplete
          freeSolo
          open={recentlyTaggedListOpened}
          clearOnBlur={false}
          inputValue={name}
          onChange={onOptionChange}
          getOptionKey={(option) => option.index}
          getOptionLabel={(option) => option.name || option || ''}
          onInputChange={onInputChange}
          renderInput={renderAutocompleteInput}
          renderOption={renderAutocompleteOption}
          slotProps={{
            paper: {
              component: renderAutocompletePaper
            }
          }}
          options={options}
        />
      </div>
    </>
  )

  const tagPlayerPopupOpened = Boolean(anchorEl)

  useEffect(() => {
    if (tagPlayerPopupOpened) {
      setTimeout(() => {
        document.addEventListener('click', documentClickRef.current)
        setRecentlyTaggedListOpened(true)
      }, 300)
    } else {
      document.removeEventListener('click', documentClickRef.current)
      setRecentlyTaggedListOpened(false)
    }
  }, [tagPlayerPopupOpened])

  const renderAddNewUserPopup = () => (
    <AddNewUserPopup
      open={addNewUserPopupOpened}
      anchorEl={popperRef.current}
      playerName={name || initialName}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={() => {
        setAddNewUserPopupOpened(false)
        setTimeout(() => setRecentlyTaggedListOpened(true), 500)
      }}
      onAddUserEmail={() => {
        setAddNewUserPopupOpened(false)
        setAddUserEmailPopupOpened(true)
      }}
      onAddUserPhone={() => {
        setAddNewUserPopupOpened(false)
        setAddUserPhonePopupOpened(true)
      }}
    />
  )

  const renderAddUserPhonePopup = () => (
    <AddUserPhonePopup
      open={addUserPhonePopupOpened}
      anchorEl={popperRef.current}
      playerName={name || initialName}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={(opts) => {
        if (opts?.success) {
          closeAllPopups()
        } else {
          setAddUserPhonePopupOpened(false)
          setTimeout(() => setRecentlyTaggedListOpened(true), 500)
        }
      }}
    />
  )

  const renderAddUserEmailPopup = () => (
    <AddUserEmailPopup
      open={addUserEmailPopupOpened}
      anchorEl={popperRef.current}
      playerName={name || initialName}
      vid={vid}
      playerIdx={playerIdx}
      aiEngineVersion={aiEngineVersion}
      onClose={(opts) => {
        if (opts?.success) {
          closeAllPopups()
        } else {
          setAddUserEmailPopupOpened(false)
          setTimeout(() => setRecentlyTaggedListOpened(true), 500)
        }
      }}
    />
  )

  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: tagPlayerPopupOpened ? 'block' : 'none',
      zIndex: 1200
    }}
    >
      <Container
        open={tagPlayerPopupOpened}
        anchorEl={anchorEl}
        placement='bottom-start'
        slotProps={{
          root: {
            ref: popperRef
          }
        }}
      >
        {renderContent()}
      </Container>
      {renderAddNewUserPopup()}
      {renderAddUserEmailPopup()}
      {renderAddUserPhonePopup()}
    </Box>
  )
}
